import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ButtonDownloadAsPng } from './buttonDownloadAsPng';

export const ButtonDownloadSimulationAsPng = () => {
    /**SELECTORS */
    const chartBase64Image = useSelector((store: ApplicationState) => store.settingsReducer.simulationChartBase64Image);
    return (
        <ButtonDownloadAsPng chartBase64Image={chartBase64Image} filename="Simulation" />
    )
}
