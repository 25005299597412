import classes from "./header.module.scss"
import sprites from "./../../assets/img/sprites.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faCircleUser, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';


const Header = (props: any) => {
        return (
        <div className={classes.header}>
            <div className={classes.header__logo}>
                <a href="/">
                    <div className={classes.header__logo__left}>
                        <svg>
                            <use xlinkHref={`${sprites}#logo`}></use>
                        </svg>
                    </div>
                </a>
            </div>
            <div className={classes.header__menu}>
                <div className={classes.header__menu__item}>
                    <span><a href="/">
                        <div className={classes.header__menu__item__iconbox}>
                            <FontAwesomeIcon icon={faCircleUser} />
                            <span>Hi {props.user.displayName}</span>
                        </div></a>
                    </span>
                    <span><a href="/releaseNotes">
                        <div className={classes.header__menu__item__iconbox}>
                        <FontAwesomeIcon icon={faClockRotateLeft} />
                        <span>Release Notes</span>
                    </div></a>
                    </span>
                    <span><a href="/" onClick={props.authButtonMethod}>
                        <div className={classes.header__menu__item__iconbox}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        <span>Log Out</span>
                    </div></a>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Header;
