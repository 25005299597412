import classes from './../style.module.scss';
import { GetPriceButton } from './buttonGetPrice';
import SimulationButton from './buttonSimulate';

interface Props {
    disableGetPrice: boolean
    disableSim: boolean,
    isEntitySelected: boolean,
    isPriceListSelected: boolean
}
export const SettingsButtons = ({disableGetPrice, disableSim, isEntitySelected, isPriceListSelected}: Props) => {

    return (
        <div className={classes.cta}>
            <div className={classes.cta__button}>
                <GetPriceButton disableGetPrice={disableGetPrice} isPriceListSelected={isPriceListSelected} />
            </div>
            <div className={classes.cta__button}>
                <SimulationButton disableSim={disableSim} isEntitySelected={isEntitySelected}/>
            </div>
        </div>
    );
}
