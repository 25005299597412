import Form from 'react-bootstrap/Form';

import styles from 'common/components/divContainer/style.module.scss';
import { DivContainer, DivContainer50 } from "common/components/divContainer";
import { Iso } from "./iso";
import { Node } from './node';
import { useState } from 'react';

interface Props{
    isLoadingEnabled: boolean
}
export const IsoNode = ({ isLoadingEnabled }: Props) => {

    /**Constants */
    const labelTitleIso = "ISO";
    const labelTitleNode = "LMP";

    const [isNodeActive, setNodeActive] = useState(true);

    return (

        <DivContainer>
            <>
                <DivContainer50 label={labelTitleIso}>
                    <Iso isLoadingEnabled={isLoadingEnabled} />
                </DivContainer50>
                <div className={styles.component50}>
                    <div className={styles.custom_node_switch}>
                        <label>{labelTitleNode}</label>
                        <Form.Check
                            bsPrefix={styles.custom_node_switch_form_check}
                            type="switch"
                            id="custom-switch"
                            label="Active"
                            checked={isNodeActive}
                            onChange={change => setNodeActive(change.target.checked)}
                        />
                    </div>
                    <Node isLoadingEnabled={isLoadingEnabled}  isNodeActive={isNodeActive}/>
                </div>
            </>
        </DivContainer>
    )
}


