import { useState } from "react";
import { Accordion } from "react-bootstrap";

import { CardPriceProfile } from "./cardPriceProfile";
import { CardSimulationChart } from "./cardSimulationChart";
import { CardSimulationTable } from "./cardSimulationTables";
import { ToggleState } from "common/interfaces/toggleState";

interface Props {
    checkAypaToken: () => void;
}
export const MainSection = ({checkAypaToken}: Props) => {

    const [toggleState] = useState<ToggleState>({ openCardId: '0', cardIdToOpen: '0' });

    return (
        <Accordion defaultActiveKey="0">
            <CardPriceProfile eventKey="0" stack={toggleState} checkAypaToken={checkAypaToken}/>
            <CardSimulationChart eventKey="1" stack={toggleState} checkAypaToken={checkAypaToken}/>
            <CardSimulationTable eventKey="2" stack={toggleState} checkAypaToken={checkAypaToken}/>
        </Accordion>
    )
}
