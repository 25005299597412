import classes from './style.module.scss';

import { useAuth } from 'common/hooks/useAuth';


interface NotFoundProps {
    getAypaToken: Function
}

export const NotFoundPage = ({ getAypaToken }: NotFoundProps) => {
    const { isValid } = useAuth({ getAypaToken });


    return (
        <>
            { isValid &&
                <>
                    <div className={classes.notFound}>
                        PAGE NOT FOUND
                    </div>
                </>
            }
        </>
    )
}
