import styles from './style.module.scss';

type DivContainerProps = {
    style?: {},
    children: JSX.Element
}
export const DivContainer = ({ children, style }: DivContainerProps) => {

    return (
        <div className={styles.component} style={style}>
            {children}
        </div>
    )
}

type DivContainer50Props = {
    label?: string,
    important?: boolean
    style?: string,
    children: JSX.Element
}

export const DivContainer50 = ({ label, important, children }: DivContainer50Props) => {

    return (
        <div className={styles.component50}>
            {label && <label>{label}</label>}
            {children}
        </div>
    )
}

type DivContainer100Props = {
    style?: {},
    important?: boolean
    label?: string,
    children?: JSX.Element,
    help?: JSX.Element
}

export const DivContainer100 = ({ label, style, important, children, help }: DivContainer100Props) => {

    return (
        <div className={styles.component100} style={style}>
            {help && 
            <div className={styles.lableAndHelp}>
                {label && <label>{label}</label>}
                {help}
            </div>
            }
            {!help && label && (
                <label>{label}</label>
            )}
            {children}
        </div>
    )
}



