import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import classes from "./../../style.module.scss";
import { SimulationChartWrapper } from "./../simulationChartWrapper";
import { setSimulationDisplay } from "pages/home/stores/slices/settings-slice"
import { ApplicationState } from "store";

export const SimulationTabs = (props: any) => {

    /**Contants */
    const dispatch = useDispatch();
    const granularity = useSelector((store: ApplicationState) => store.settingsReducer.granularity);
    const perfectForesight = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesight);

    /**States */
    const [key, setKey] = useState('Overall');
    const [group, setGroup] = useState('hour');

    const setKeyHandler = (newkey: any) => {
        if (props.isLoading || newkey === key) return false;
        dispatch(setSimulationDisplay());
        setKey(newkey);
    }

    useEffect(() => {
        if (granularity === "FIVEMIN" && perfectForesight) {
            setGroup('five_min')
        } else {
            setGroup('hour')
        }
    }, [granularity, perfectForesight])

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k: any) => setKeyHandler(k)}
            justify
            >
            <Tab eventKey="Overall" title="Overall">
                {key === 'Overall' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <SimulationChartWrapper group={[group]} responseGroupingProp=''/>
                    </div>
                }
            </Tab>

            <Tab eventKey="Month" title="Monthly">
                {key === 'Month' &&
                    <div className={`${classes.tabContent} ${classes.oneChart}`}>
                        <SimulationChartWrapper group={[group, 'month']} responseGroupingProp='month'/>
                    </div>
                }
            </Tab>
            {!perfectForesight && (
                <Tab eventKey="Quarter" title="Quarterly">
                    {key === 'Quarter' &&
                        <div className={`${classes.tabContent} ${classes.oneChart}`}>
                            <SimulationChartWrapper group={[group, 'quarter']} responseGroupingProp='quarter'/>
                        </div>
                    }
                </Tab>
            )}
            {perfectForesight && (
                <Tab eventKey="wint_sum" title="Seasonally">
                    {key === 'wint_sum' &&
                        <div className={`${classes.tabContent} ${classes.oneChart}`}>
                            <SimulationChartWrapper group={[group, 'wint_sum']} responseGroupingProp='wint_sum'/>
                        </div>
                    }
                </Tab>
            )}

            {perfectForesight && (
                <Tab eventKey="Year" title="Yearly">
                    {key === 'Year' &&
                        <div className={`${classes.tabContent} ${classes.oneChart}`}>
                            <SimulationChartWrapper group={[group, 'year']} responseGroupingProp='year'/>
                        </div>
                    }
                </Tab>
            )}
            {perfectForesight && (
               <Tab eventKey="wint_sum_by_year" title="Season by Year">
                   {key === 'wint_sum_by_year' &&
                       <div className={`${classes.tabContent} ${classes.multiOneChart}`}>
                           <SimulationChartWrapper group={[group, 'year', 'wint_sum']} responseGroupingProp='year'/>
                       </div>
                   }
               </Tab>
           )}
            {perfectForesight && (
               <Tab eventKey="Month_by_Year" title="Month by Year">
                   {key === 'Month_by_Year' &&
                       <div className={`${classes.tabContent} ${classes.multiOneChart}`}>
                           <SimulationChartWrapper group={[group, 'year', 'month']} responseGroupingProp='year'/>
                       </div>
                   }
               </Tab>
           )}
        </Tabs>

    )
}
