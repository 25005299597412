import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { SagaReturnType } from "@redux-saga/core/effects";


import { setTablesLoading } from "../slices/table-slice";
import { getHybridRevenue } from "common/adapters/HybridRevenue";
import { executeHybridRevenueComplete } from "../slices/chart-slice";

const call: any = Effects.call;
const put: any = Effects.put;

export function* fetchHybridRevenues(action: any): SagaIterator {

    
    try {
        type HybridRevenue = SagaReturnType<typeof getHybridRevenue>;
        yield put(setTablesLoading(true));
        const response: HybridRevenue = yield call(getHybridRevenue, action.parameters);
        yield put(executeHybridRevenueComplete({data: response.data, error: null}));
    } catch (error) {
        console.error('fetchHybridRevenues', error)
        yield put(executeHybridRevenueComplete({data: null, error: error}));
    }
    yield put(setTablesLoading(false));

}