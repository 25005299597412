import { getAllRegionByFilter } from "common/adapters/entities"
import { setRegionId } from "pages/home/stores/slices/settings-slice"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import { ApplicationState } from "store"
import toast from 'react-hot-toast';

interface Props {
    isLoadingEnabled: boolean
}

export const Region = ({ isLoadingEnabled }: Props) => {

    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);

    const dispatch = useDispatch()

    const [listRegion, setListRegion] = useState<Array<any>>([]);
    const [regionSelected, setRegionSelected] = useState<object>()
    const [isListLoading, setListLoading] = useState<boolean>(true);

    const handlerOnChangeRegion = (selectedOption: any) => {
        setRegionSelected(selectedOption)
        if (selectedOption) {
            dispatch(setRegionId({regionId: selectedOption.value, timezone: selectedOption.timezone, regionName: selectedOption.label}))
        } else {
            dispatch(setRegionId({regionId: null, timezone: null, regionName: null}))
        }
    }

    const loadRegionList = async () => {
        try {
            const response = await getAllRegionByFilter(sourceId);
            const options = response.data.items.map((s: any) => {
                return { value: s.id, label: s.name, timezone: s.timezone }
            })
            setListRegion(options)
            handlerOnChangeRegion(options[0])
        } catch {
            setListRegion([])
            toast.error("Failed to load the Region list. Please try again.");
        } finally {
            setListLoading(false)
        }

    }

    useEffect(() => {
        loadRegionList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Select
                id="regionDropdown"
                onChange={handlerOnChangeRegion}
                options={listRegion}
                value={regionSelected}
                className='react-select-container'
                classNamePrefix="react-select"
                menuPlacement='auto'
                isDisabled={isLoadingEnabled}
                isLoading={isListLoading}
            />
        </>
    )
}