import { useState } from "react";
import { useDispatch } from "react-redux";

import { RadioButton } from "common/components/radioButton";
import { DivContainer, DivContainer100 } from "common/components/divContainer";
import { setFixedSchedule, setPerfectForesight } from "pages/home/stores/slices/settings-slice";
import { HelpHover } from "common/components/helpButton";

interface Props{
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const SimulationType = ({ isLoadingEnabled, isEntitySelected }: Props) => {
    const initHelp = ["Simulation Type can be selected once an entity is selected"]
    /**State */
    const [selectedOption, setSelectedOption] = useState("noSimulate");
    const [messageList, setMessageList] = useState<Array<string>>(initHelp);
    const dispatch = useDispatch();

    const onChangeValue = (target: any) => {
        setSelectedOption(target.value);   
        if (target.value === 'fixedSchedule') {
            dispatch(setFixedSchedule(true));
            setMessageList(["You must run 'Get Price' and enter Rated Power before you can Simulate.",
                "Hours in Standard Time.",
                "Hourly data is limited to 10 years."])
        } else if (target.value === 'perfectForesight') {
            dispatch(setPerfectForesight(true));
            setMessageList(["NRG Stream: You must run 'Get Price' before you can Simulate.",
                "All other sources: You can Simulate without 'Get Price'.",
                "Enter Rated Power and Cycle Per Day.",
                "Hours in Source's Time.  No conversion is made.",
                "No limit on hourly data.  5 year limit on 5min data."])
        } else if (target.value === 'noSimulate') {
            dispatch(setFixedSchedule(false));
            dispatch(setPerfectForesight(false));
            setMessageList(initHelp)
        }
    };

    return (
        <DivContainer>
            <DivContainer100 label="Simulation Type" help={<HelpHover messageList={messageList} largeMarg={true}></HelpHover>}>
                <>
                    <RadioButton
                        id="noSimulate"
                        name="graph"
                        value="noSimulate"
                        label="None"
                        disabled={!isEntitySelected || isLoadingEnabled}
                        checked ={selectedOption === "noSimulate"}
                        callback={onChangeValue} />
                    <RadioButton
                        id="fixedSchedule"
                        name="graph"
                        value="fixedSchedule"
                        label="Fixed Schedule"
                        disabled={!isEntitySelected || isLoadingEnabled}
                        checked ={selectedOption === "fixedSchedule"}
                        callback={onChangeValue} />
                    <RadioButton
                        id="perfectForesight"
                        name="graph"
                        value="perfectForesight"
                        label="Perfect Foresight"
                        disabled={!isEntitySelected || isLoadingEnabled}
                        checked ={selectedOption === "perfectForesight"}
                        callback={onChangeValue} />
                </>
            </DivContainer100>
        </DivContainer>
    )
}

export default SimulationType;
