/**
 * This file contains functions for interacting with the API to retrieve all price and forecast data.
 */

import config from 'config';
import { api } from './api';

/**
 * Fetches all sources.
 * @returns Array of all sources with source id and name.
 */
export const getAllSource = () => {
    api.defaults.baseURL = config.baseUrl;
    return api.get('source/');
}

/**
 * Fetches all ISO codes for a given source.
 * @param sourceId - The ID of the source.
 * @returns Array of all ISO with iso id, name, timezone and currency.
 */
export const getAllIso = (sourceId: number) => {
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    params.append("source_id", `${sourceId}`)
    return api.get('region/', { params });
}

/**
 * Fetches all regions based on the params passed.
 * @param sourceId - The ID of the source.
 * @param forecastId - Optional ID of the forecast for filtering.
 * @returns Array of all regions with region id, name, timezone and currency.
 */
export const getAllRegionByFilter = (sourceId: number, forecastId?: number | null) => {
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    if (forecastId) {
        params.append("forecast_id", `${forecastId}`)
    }
    params.append("source_id", `${sourceId}`)
    return api.get('region/', { params });
}

/**
 * Fetches all forecasts based on the params passed.
 * @param sourceId - The ID of the source.
 * @param regionId - Optional ID of the region for filtering.
 * @param locationId - Optional ID of the location for filtering.
 * @returns Array of all forecasts with forecast id, inflation_vector, min_timestamp, max_timestamp, timestamp_created and timestamp_created.
 */
export const getAllForecastByFilter = (sourceId: number, regionId?: number | null, locationId?: number | null) => {
    // must convert min/max (Date) to Datetime with getLocalizedDate
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    if (regionId) {
        params.append("region_id", `${regionId}`)
    }
    if (locationId) {
        params.append("location_id",  `${locationId}`)
    }
    params.append("source_id",  `${sourceId}`)
    return api.get('forecast/', { params });
}

/**
 * Fetches all locations based the params passed.
 * @param sourceId - The ID of the source.
 * @param regionId - Optional ID of the region for filtering.
 * @param forecastId - Optional ID of the forecast for filtering.
 * @returns Array of all locations with location id and name.
 */
export const getAllLocationByFilter = (sourceId: number, regionId?: number | null, forecastId?: number | null) => {
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    params.append("source_id",  `${sourceId}`)
    if (forecastId) {
        params.append("forecast_id",  `${forecastId}`)
    }
    if (regionId) {
        params.append("region_id",  `${regionId}`)
    }
    return api.get('location/', { params });
}

/**
 * Fetches all nodes based the params passed. This function is use ONLY by Nodal component.
 * @param sourceId - The ID of the source.
 * @param name - Optional name for filtering.
 * @param regionId - Optional ID of the region for filtering.
 * @param forecastId - Optional ID of the forecast for filtering.
 * @param locationId - Optional ID of the location for filtering.
 * @param offset - Optional offset for paginated results.
 * @returns Array of all nodes with node id, node name and region name.
 */
export const getAllNodeByFilter = (sourceId: number, name?: string, regionId?: number | null,
    forecastId?: number | null, locationId?: number | null, offset?: number) => {
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    params.append("source_id", `${sourceId}`)
    if (forecastId) {
        params.append("forecast_id", `${forecastId}`)
    }
    if (regionId) {
        params.append("region_id", `${regionId}`)
    }
    if (locationId) {
        params.append("location_id", `${locationId}`)
    }
    if (name) {
        params.append("long_name", name);
    }
    if (offset) {
        params.append("offset", `${offset}`);
    }
    return api.get('node/', { params });
}

/**
 * Fetches all nodes based on the params passed. This is used by Node component in isoNode.
 * @param sourceId - The ID of the source.
 * @param name - Optional name for filtering.
 * @param iso - Optional ISO code for filtering.
 * @param offset - Optional offset for paginated results.
 * @returns Array of all nodes with node id and node name.
 */
export const getAllPriceEntitiesByFilter = (sourceId: number, name?: string, iso?: number | null, disabled?: boolean | null, offset?: number) => {
    let params = new URLSearchParams();
    params.append("limit", '100');
    params.append("source_id", `${sourceId}`)
    if (offset) {
        params.append("offset", `${offset}`)
    } else {
        params.append("offset", '0');
    }

    if (name) {
        params.append("name_location", name);
    }

    if (iso) {
        params.append("region_id", `${iso}`);
    }
    if (disabled === false || disabled === true) {
        params.append("disabled", `${disabled}`);
    }
    

    api.defaults.baseURL = config.baseUrl;
    return api.get(`node/`, { params });
}

/**
 * Fetches forecast key based on the params passed. This is used by perfect foresight to get the key without running Get Price
 * @param parameters.id - The ID of the forecast.
 * must supply either node_id or location_id
 * @param parameters.node_id - Optional node_id for the forecast when source = Nodal.
 * @param parameters.location_id - Optional location_id for the forecast when source = Wood Mac or Zonal.
 * @returns  `{key string: forecast_key_name}
 */
export const getForecastKey = (parameters: any) => {
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    // only send either node id or location id
    if (parameters.node_id) {
        params.append("node_id", parameters.node_id.toString());
    } else if (parameters.location_id) {
        params.append("location_id", parameters.location_id.toString());
    }

    api.defaults.baseURL = config.baseUrl;
    return api.get(`forecast/${parameters.id}/key`,{ params });
}

/**
 * Triggers the custom upload lambda that loads the forecasted data into the app
 * @returns  ` good or bad status
 */
export const triggerCustomUpload = () => {
    api.defaults.baseURL = config.baseUrl;
    return api.get(`admin/invoke-custom-data`);
}

/**
 * Deletes the forecast - does not delete the regions/locations if empty
 * @param forecastId - The ID of the forecast.
 * @param sourceId - Optional node_id for the forecast when source = Nodal.
 * @returns  `{key string: forecast_key_name}
 */
export const deleteForecast = (forecastId: number, sourceId: number) => {
    api.defaults.baseURL = config.baseUrl;
    let params = new URLSearchParams();
    params.append("source_id", `${sourceId}`);
    api.defaults.baseURL = config.baseUrl;
    return api.delete(`forecast/${forecastId}/`,{ params });
}
