import { useSelector, useDispatch } from "react-redux";
import { getHybridRevenueCsv } from "common/adapters/HybridRevenue";
import { ApplicationState } from "store";
import sprites from "common/assets/img/sprites.svg";
import SpinnerButton from "../sprinnerButton";
import classes from "./../../style.module.scss";
import { DataToFileDownload, createMetadataSimulate } from "common/utils/utils";
import { setSimulateFail, setCSVDownloading } from "pages/home/stores/slices/chart-slice";
import config from 'config';
import { wsConnect, wsSendMessage } from "pages/home/stores/actions/websocket-action";
import { useEffect, useState } from "react";
import { SourceTypes, useNodesInSource } from "pages/home/stores/types/source-types";
import { setSimulateButton } from "pages/home/stores/slices/settings-slice";

export const ButtonDownloadSimulationCsv = () => {
    /**SELECTORS */
    const hybridRevenueId = useSelector((store: ApplicationState) => store.chartReducer.hybridRevenueId);
    const perfectForesightId = useSelector((store: ApplicationState) => store.chartReducer.perfectForesightId);
    const completedDownloadCSV = useSelector((store: ApplicationState) => store.chartReducer.completedDownloadCSV);
    const loadedData = useSelector((store: ApplicationState) => store.chartReducer.loadedData);
    const isCSVDownloading = useSelector((store: ApplicationState) => store.chartReducer.isCSVDownloading);
    const executeRunPerfectForesight = useSelector((store: ApplicationState) => store.chartReducer.executeRunPerfectForesight);
    const executeRunFixedSchedule = useSelector((store: ApplicationState) => store.chartReducer.executeRunFixedSchedule); 
    const executeOperation = useSelector((store: ApplicationState) => store.chartReducer.executeOperation); 
    const socketFixedSchedule = useSelector((store: ApplicationState) => store.websocketReducer.socketFixedSchedule);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    /**CONSTANTS */
    const dispatch = useDispatch();
    const titleButton = 'Download Raw Data as .CSV';
    const titleFile = 'Simulation';
    const useNodes = useNodesInSource(settingsReducer.sourceId);
    const timeoutTime = 180000; //3min update error message to match

    const [timeOutId, setTimeOutId] = useState<any>(null); 

    const downloadAsCSV = (event: any) => {
        dispatch(setCSVDownloading(true));
        event.target.disabled = true;

        if (hybridRevenueId) {
          getHybridRevenueCsv(hybridRevenueId).then((result: any) => {
            result.data = createMetadataSimulate(settingsReducer) + "\n" + result.data;
            DataToFileDownload(result.data, titleFile);
            event.target.disabled = false;
            dispatch(setCSVDownloading(false));
          }).catch(error => {
            console.log(error);
            dispatch(setCSVDownloading(false));
          });
        }
    }

    useEffect(() => {
        if (isCSVDownloading && !socketFixedSchedule && perfectForesightId) {
            dispatch(wsConnect(config.fixedScheduleWs));
        }
        if (isCSVDownloading && socketFixedSchedule && perfectForesightId) {
            const parameter = {
                action: "perfect-foresight-download",
                id: perfectForesightId
            };
            const jsonString: string = JSON.stringify(parameter);
            dispatch(wsSendMessage(jsonString, config.fixedScheduleWs));

            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }
            const timeOut = setTimeout(() => {
                dispatch(setSimulateFail({"message": "Request timed out.", "details": "Request took longer than 3mins."}));
                dispatch(setSimulateButton(false));
            }, timeoutTime);
            setTimeOutId(timeOut)
        } else {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCSVDownloading, socketFixedSchedule, perfectForesightId]);

    useEffect(() => {
        if (completedDownloadCSV) {
            let priceJson = null;
            try {
                // only errors are in json
                priceJson = JSON.parse(loadedData)
            } catch(e) {
                priceJson = null;
            }
            if (priceJson) {
                responseDoneWithError(priceJson);
            } else {
                responseWithoutError(loadedData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedDownloadCSV]);

    const responseDoneWithError = (data: any) => {  
        console.error("Error on download: ", data.error)     
        dispatch(setSimulateFail({ message: data.error }));
        dispatch(setSimulateButton(false));
    }

    const responseWithoutError = (data: any) => {
        // convert data to csv and save 
        const price = settingsReducer.priceList[0];
        let filename = "PerfectForesight"
        if (price.sourceId === SourceTypes.NRG_STREAM) {
            filename = "PerfectForesight_" + price.streamName;
        } else {
            if (useNodes) {
                filename = "PerfectForesight_" + price.forecastName + "_" + price.nodeName;
            } else {
                filename = "PerfectForesight_" + price.forecastName + "_" + price.locationName;
            }
        }

        data = createMetadataSimulate(settingsReducer) + "\n" + data;
        DataToFileDownload(data, filename);
        dispatch(setCSVDownloading(false));
    }

    return (
        <div className={classes.card__dropdownitem}>
            <button onClick={downloadAsCSV}
                disabled={executeRunPerfectForesight || executeOperation || executeRunFixedSchedule || isCSVDownloading}>
                {isCSVDownloading && <SpinnerButton />}
                {!isCSVDownloading && <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-export`}></use>
                    </svg>
                </span>}
                {titleButton}
            </button>
        </div>
    )
}
