import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';

import config from 'config';
import { getUserDetails } from '../adapters/GraphService';

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  user: any;
  login: Function;
  logout: Function;
  getAccessToken: Function;
  setError: Function;
  getAypaToken: Function;
  isLoading: boolean;
}

interface AuthProviderState {
  error: any;
  isAuthenticated: boolean;
  user: any;
  isLoading: boolean;
}

export default function withAuthProvider<T extends React.Component<AuthComponentProps>>
  (WrappedComponent: new (props: AuthComponentProps, context?: any) => T): React.ComponentClass {
  return class extends React.Component<any, AuthProviderState> {
    private publicClientApplication: PublicClientApplication;

    constructor(props: any) {
      super(props);
      this.state = {
        error: null,
        isLoading: false,
        isAuthenticated: false,
        user: {}
      };

      
      // Initialize the MSAL application object
      this.publicClientApplication = new PublicClientApplication({
        auth: {
          authority: config.authAuthority,
          clientId: config.appId,
          redirectUri: config.redirectUri
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true
        }
      });
    }

    async componentDidMount() {
        await this.publicClientApplication.initialize();
      // If MSAL already has an account, the user
      // is already logged in
      const accounts = this.publicClientApplication.getAllAccounts();

      if (accounts && accounts.length > 0) {
        // Enhance user object with data from Graph
        this.getUserProfile();
        this.getAypaToken();
      }
    }

    render() {
      return <WrappedComponent
        error={this.state.error}
        isAuthenticated={this.state.isAuthenticated}
        isLoading={this.state.isLoading}
        user={this.state.user}
        login={() => this.login()}
        logout={() => this.logout()}
        getAccessToken={(scopes: string[]) => this.getAccessToken(scopes)}
        getAypaToken={(): Promise<string | undefined> => this.getAypaToken()}
        setError={(message: string, debug: string) => this.setErrorMessage(message, debug)}
        {...this.props} />;
    }

    async login() {
      try {
        // Login via popup
        // Scopes are provided here for user consent ?
        await this.publicClientApplication.loginPopup(
          {
            scopes: config.graphScopes.concat(config.aypaScopes),
            prompt: "select_account"
          });

        // After login, get the user's profile
        await this.getUserProfile();
        await this.getAypaToken();
      }
      catch (err) {
        this.setState({
          isLoading: false,
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err as Error)
        });
      }
    }

    logout() {
      this.publicClientApplication.logout();
    }

    async getAccessToken(scopes: string[]): Promise<string> {
      try {
        const accounts = this.publicClientApplication
          .getAllAccounts();

        if (accounts.length <= 0) throw new Error('login_required');

        // console.log("Scope:\n" + scopes)

        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await this.publicClientApplication
          .acquireTokenSilent({
            scopes: scopes,
            account: accounts[0]
          });

        //console.log("Access Token:\n" + silentResult.accessToken)
        return silentResult.accessToken;
      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err as Error)) {
          var interactiveResult = await this.publicClientApplication
            .acquireTokenPopup({
              scopes: scopes
            });

          return interactiveResult.accessToken;
        } else {
          throw err;
        }
      }
    }

    async getUserProfile() {
      this.setState({
        isLoading: true
      });

      try {
        var graphAccessToken = await this.getAccessToken(config.graphScopes);

        if (graphAccessToken) {
          // Get the user's profile from Graph
          var user = await getUserDetails(graphAccessToken);
          this.setState({
            isLoading: false,
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              timeZone: user.mailboxSettings?.timeZone || 'UTC',
              timeFormat: user.mailboxSettings?.timeFormat
            },
            error: null
          });
        }
      }
      catch (err) {
        this.setState({
          isAuthenticated: false,
          isLoading: false,
          user: {},
          error: this.normalizeError(err as Error)
        });
      }
    }

    async getAypaToken() {
      try {
        var aypaAccessToken = await this.getAccessToken(config.aypaScopes);
        return aypaAccessToken;
      }
      catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err as Error)
        });
      }
    }

    setErrorMessage(message: string, debug: string) {
      this.setState({
        error: { message: message, debug: debug }
      });
    }

    normalizeError(error: string | Error): any {
      var normalizedError = {};
      if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error)
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error: Error): boolean {
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
      );
    }
  }
}
