import axios from "axios";
import { handleException } from "common/exceptions/handleExceptions";
import { useAuth } from "common/hooks/useAuth";
import config from 'config';

const api = axios.create({
    baseURL: config.baseUrl,
});

axios.defaults.withCredentials = true;
api.defaults.withCredentials = false;



const useAxiosInterceptors = (getAypaToken: Function) => {
    const { getToken } = useAuth({ getAypaToken });
    
    api.interceptors.request.use(
        request => {
            let accessToken = window.sessionStorage.getItem(config.keyApiSession);
            request.headers["Authorization"] = "Bearer " + accessToken;
            return request;
        },
        error => {
            return Promise.reject(error);
        }
    );
    
    api.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            // retry if token is expired, but still have active session
            if (error.response.status === 401 && !originalRequest._retry) {
              originalRequest._retry = true;
              try {
                await getToken();
                let accessToken = window.sessionStorage.getItem(config.keyApiSession);
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return api(originalRequest);
              } catch (refreshError) {
                const exceptionError = handleException(refreshError);
                return Promise.reject(exceptionError);
              }
            }
            const exceptionError = handleException(error);
            return Promise.reject(exceptionError);
        }
    );
}


export { api, useAxiosInterceptors };
