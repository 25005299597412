import sprites from "common/assets/img/sprites.svg";
import { useState } from "react";
import classes from './helpButton.module.scss';

interface Props{
    messageList: Array<string>,
    largeMarg: boolean
}

const HelpButton = ({messageList}: Props) => {
    const [isHelpVisible, setHelpVisible] = useState(false);
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });


    const toggleHelp = (event: any) => {
        const { top, left, height } = event.target.getBoundingClientRect();
        setHelpVisible(!isHelpVisible);
        setPopoverPosition({
            top: top + height + 5,
            left: -left/2,
          });
    };



  return (
    <div>
        <button className={classes.helpButton} onClick={toggleHelp}>
        <svg>
            <use xlinkHref={`${sprites}#help`}></use>
        </svg>
        Help
        </button>
        {isHelpVisible && (
            <div className={classes.popover} style={popoverPosition}>
                <div className={classes.popover__title}>
                    Read Me
                    <button  onClick={toggleHelp}>
                    <svg>
                        <use xlinkHref={`${sprites}#x-mark`}></use>
                    </svg>
                </button>
                </div>
                
                {messageList.map((entry, index) => (
                    <div key={index}>{entry}</div>
                ))}
            </div>
        )}
    </div>
  );
};

const HelpHover = ({messageList, largeMarg}: Props) => {
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const [showHelp, setShowHelp] = useState(false);


    const handleMouseEnter = (event: any) => {
        const rect = event.target.getBoundingClientRect();
        setPopoverPosition({
            top: rect.top,
            left: rect.left,
          });
        setShowHelp(true);
    };
  
    const handleMouseLeave = () => {
        setShowHelp(false);
    };

  return (
    <div className={`${classes.hoverover}  ${largeMarg ? classes.largeMarg : classes.smallMarg}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <svg>
            <use xlinkHref={`${sprites}#help`}></use>
        </svg>
        {showHelp && (
            <div className={classes.hoverover__message} style={popoverPosition}>
                {messageList.map((entry, index) => (
                    <li key={index}>
                        {entry}
                    </li>
                    
                ))}
            </div>
        )}
    </div>
  );
};

export {HelpButton, HelpHover};