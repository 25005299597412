import { DivContainer, DivContainer50 } from "common/components/divContainer";
import { Forecast } from "../sharedSourceComponents/forecast";
import { Region } from "../sharedSourceComponents/region";
import { Node } from "./node";
import { Location } from "../sharedSourceComponents/location";

interface Props {
    isLoadingEnabled: boolean
    showLocationAndNode: boolean
}
export const Nodal = ({ isLoadingEnabled, showLocationAndNode }: Props) => {

    /**Constants */
    const labelTitleRegion = "Region";
    const labelTitleForecast = "Forecast";
    const labelTitleNode = "Node";
    const labelTitleLocaiton = "Location";

    return (
        <>
            <DivContainer>
                <>
                    <DivContainer50 label={labelTitleRegion}>
                        <Region isLoadingEnabled={isLoadingEnabled} />
                    </DivContainer50>

                    <DivContainer50 label={labelTitleForecast}>
                        <Forecast isLoadingEnabled={isLoadingEnabled} />
                    </DivContainer50>

                </>
            </DivContainer>
            {showLocationAndNode && (
                <DivContainer>
                    <>
                        <DivContainer50 label={labelTitleLocaiton}>
                            <Location isLoadingEnabled={isLoadingEnabled} />
                        </DivContainer50>
                        <DivContainer50 label={labelTitleNode}>
                            <Node isLoadingEnabled={isLoadingEnabled} />
                        </DivContainer50>
                    </>
                </DivContainer>
            )}
        </>
    )
}