import {api} from './api';
import config from 'config';


export const getMeanPerfectForesight = (id: string | null) => {
    api.defaults.baseURL = config.fixedScheduleServiceBaseURL;
    return api.get(`perfect-foresight/${id}/mean`);
}

export const getSumPerfectForesight = (id: string | null, parameters:any) => {
    let params = new URLSearchParams();
    // console.log(parameters)
    params.append("rated_power", `${parameters.rated_power}`);
    params.append("roundtrip_efficiency", `${parameters.roundtrip_efficiency}`);
    params.append("duration", `${parameters.duration}`);
    api.defaults.baseURL = config.fixedScheduleServiceBaseURL;
    return api.get(`perfect-foresight/${id}/sum`, {params});
}

export const getSummaryPerfectForesight = (id: string | null) => {
    api.defaults.baseURL = config.fixedScheduleServiceBaseURL;
    return api.get(`perfect-foresight/${id}`);
}