import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select"
import { LineData } from "common/interfaces/lineTypes";
import AYPALineChart from "common/components/lineChart";
import { getConfig, formatPriceDataAll } from "common/components/lineChart/utils";
import classes from "./../../style.module.scss";
import { ApplicationState } from "store";
import { setGetPriceButton, setPriceImage } from "pages/home/stores/slices/settings-slice";
import { Loading } from "pages/home/mainSection/components/loading";
import { AllPriceData, PriceData, PriceDataAverage, PriceDataChart } from "common/interfaces/priceData";
import { five_min_labels, hour_labels, wint_sum, months } from "common/components/lineChart/values";
import { PriceListData } from "common/interfaces/priceListData";
import DefaultButton from "common/components/button";
import { setPeakEnd, setPeakStart, toggleOnOffPeakRunPriceLookup } from "pages/home/stores/slices/prices-slice";
import { HelpHover } from "common/components/helpButton";
import { averagePriceData, formatCurrency } from "common/utils/utils";
import { Table } from "reactstrap";

interface Props {
    group: Array<string>,
    responseGroupingProp: string
}
export const PriceProfileChartWrapper = (props: Props) => {
    
    /**CONSTANTS */
    const dispatch = useDispatch();
    const mode = props.group.length === 3 ? props.group[2] : null;
    const hoursList = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    const hoursOptions = hoursList.map((s: number) => {
        return { value: s, label: s.toString() + " hr" };
    });
    const helpPrices = ["Displays the average price for each hour.",
    "Seasonally: Winter is Dec, Jan, and Feb.  Summer is Jun, Jul, and Aug.", 
    "Season by Year: Winter is Dec, Jan, Feb continuous.  e.g. Winter 2024 = Dec 2023, Jan 2024, Feb 2024.",
    "Peak button toggles the on/off peak prices.  Peak is the weekday's prices for the peak start and end hours.  Off Peak is the weekday’s remaining hours and all hours on weekends.",
    "Label button toggles the labels to short, hidden, and back to long."];
    let groupName = props.group.join("_")


    /**SELECTORS */
    const priceChartBase64Image = useSelector((store: ApplicationState) => store.settingsReducer.priceChartBase64Image);
    const priceList = useSelector((store: ApplicationState) => store.settingsReducer.priceList);
    const priceDataCharts = useSelector((store: ApplicationState) => store.settingsReducer.priceDataCharts);
    const isLoadingChart = useSelector((store: ApplicationState) => store.pricesReducer.isLoadingChart);
    const errorMessage = useSelector((store: ApplicationState) => store.pricesReducer.errorMessage);
    const peakStart = useSelector((store: ApplicationState) => store.pricesReducer.peakStart);
    const peakEnd = useSelector((store: ApplicationState) => store.pricesReducer.peakEnd);

    /**STATES */
    // const [longLabel, setLongLabel] = useState<boolean | null>(true);
    const [lineData, setLineData] = useState<Array<LineData>>();
    let longLabel: boolean | null = true;
    const [chartConfig, setChartConfig] = useState<any>({});
    const [chartLabels, setChartLabels] = useState<string[]>(hour_labels);
    const [peakHasError, setPeakHasError] = useState(false);
    const [selectedStart, setSelectedStart] = useState<Array<any>>([{value: peakStart, label: peakStart.toString() + " hr"}]);
    const [selectedEnd, setSelectedEnd] = useState<Array<any>>([{value: peakEnd, label: peakEnd.toString() + " hr"}]);
    const [priceDataAverage, setPriceDataAverage] = useState<Array<PriceDataAverage>>();
    

    
    // let chartLabels = hour_labels;

    const onCompleteChartRender = (chart: any) => {
        // can't download image with multiple charts - !mode
        // chart.initial - is true on first load.  false when hovering on graph
        if (chart?.chart  && !mode && !priceChartBase64Image && chart.initial) {
            dispatch(setPriceImage(chart.chart.toBase64Image()));
        }
    }

    const toggleOnOffPeaks = () => {
        dispatch(setGetPriceButton(true));
        dispatch(toggleOnOffPeakRunPriceLookup());
    }

    const updateChartLabels = () => {
        if (longLabel == null) {
            longLabel = true;
        } else if (longLabel) {
            longLabel = false;
        } else {
            longLabel = null;
        }
        // longLabel = !longLabel;
        onCompleteFetchMeanPrice({dataObjects: priceDataCharts}, longLabel);
    }

    const onCompleteFetchMeanPrice = ({dataObjects} : any, longLabel: boolean | null) => {
        let dataArrayAll = Array<PriceDataChart>();
        let hourly_data = true;
        let two_data_sets = false;
        let avgDataTable: Array<PriceDataAverage> = [];
        for (const key in dataObjects) {
            const data: AllPriceData = dataObjects[key];
            // get name of request for labels
            let matchingRequest: PriceListData | null = null;
            for (const req of priceList) {
                if (req.eventId === key) {
                    matchingRequest = req;
                    break;
                }
            }
            if (!matchingRequest) {
                continue;
            }
            if (data.hour_off || data.five_min_off) {
                two_data_sets = true;
            }
            let dataArray: Array<PriceData> | undefined;
            let dataArray2: Array<PriceData> | undefined;
            groupName = props.group.join("_");
            // the updates to props are not happening before this happens.
            if (matchingRequest.granularity === "FIVEMIN") {
                groupName = groupName.replace("hour", "five_min");
            }
            switch (groupName){
                case "hour":
                    dataArray = data.hour;
                    dataArray2 = data.hour_off;
                    break;
                case "hour_month":
                    dataArray = data.hour_month;
                    dataArray2 = data.hour_month_off;
                    break;
                case "hour_year":
                    dataArray = data.hour_year;
                    dataArray2 = data.hour_year_off;
                    break;
                case "hour_wint_sum":
                    dataArray = data.hour_wint_sum;
                    dataArray2 = data.hour_wint_sum_off;
                    break;
                case "hour_year_month":
                    dataArray = data.hour_year_month;
                    dataArray2 = data.hour_year_month_off;
                    break;
                case "hour_year_wint_sum":
                    dataArray = data.hour_year_wint_sum;
                    dataArray2 = data.hour_year_wint_sum_off;
                    break;
                case "five_min":
                    dataArray = data.five_min;
                    dataArray2 = data.five_min_off;
                    hourly_data = false;
                    break;
                case "five_min_month":
                    dataArray = data.five_min_month;
                    dataArray2 = data.five_min_month_off;
                    hourly_data = false;
                    break;
                case "five_min_year":
                    dataArray = data.five_min_year;
                    dataArray2 = data.five_min_year_off;
                    hourly_data = false;
                    break;
                case "five_min_wint_sum":
                    dataArray = data.five_min_wint_sum;
                    dataArray2 = data.five_min_wint_sum_off;
                    hourly_data = false;
                    break;
                case "five_min_year_month":
                    dataArray = data.five_min_year_month;
                    dataArray2 = data.five_min_year_month_off;
                    hourly_data = false;
                    break;
                case "five_min_year_wint_sum":
                    dataArray = data.five_min_year_wint_sum;
                    dataArray2 = data.five_min_year_wint_sum_off;
                    hourly_data = false;
                    break;
                default:
                    dataArray = data.hour;
            }
            let label = matchingRequest.name;
            let label2 = matchingRequest.name;
            if (longLabel !== null && !longLabel) {
                let lastHyphenIndex = matchingRequest.name.lastIndexOf(" | ");
                if (lastHyphenIndex !== -1) {
                    label = matchingRequest.name.substring(lastHyphenIndex+3);
                    label2 = matchingRequest.name.substring(lastHyphenIndex+3);
                }
            }
            if (two_data_sets) {
                label += " | Peak";
                label2 += " | Off Peak";
            }
            if (!dataArray) {
                continue;
            }
            const priceChartData: PriceDataChart = {
                data: dataArray,
                name: label,
                id: matchingRequest.eventId
            }
            dataArrayAll.push(priceChartData);
            if (two_data_sets && dataArray2) {
                const priceChartData2: PriceDataChart = {
                    data: dataArray2,
                    name: label2,
                    id: matchingRequest.eventId
                }
                dataArrayAll.push(priceChartData2);
            }
            avgDataTable = avgDataTable.concat(averagePriceData(dataArray, groupName, label, dataArray2, label2));
        }
        if (!hourly_data) {
            setChartLabels(five_min_labels);
        }
        const formatedData = formatPriceDataAll(dataArrayAll, props.responseGroupingProp, 'mean_value', mode);
        setLineData(formatedData);
        setChartConfig(getConfig('Price [$]', `Hour`, longLabel === null ? false : true));
        setPriceDataAverage(avgDataTable);
    }

    useEffect(() => {
        // priceDataCharts is reset on GetPriceButton
        if (Object.keys(priceDataCharts).length !== 0) {
            const config = getConfig('Price [$]', `Hour`, longLabel === null ? false : true);
            setChartConfig(config);
            onCompleteFetchMeanPrice({dataObjects: priceDataCharts}, longLabel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceDataCharts]);
    // TODO CSS and set toggle to disabled
    const handlerStartPeakInput = (data: any) => {
        if (data.value >= peakEnd  ) {
            setPeakHasError(true);
        } else {
            setPeakHasError(false);
        }
        dispatch(setPeakStart(data.value));
        setSelectedStart(data);
    }
    const handlerEndPeakInput = (data: any) => {
        if (data.value <= peakStart) {
            setPeakHasError(true);
        } else {
            setPeakHasError(false);
        }
        dispatch(setPeakEnd(data.value));
        setSelectedEnd(data);
    }

    const peakStyles = {
        container: (provided: any) => ({
          ...provided,
          width: '95px'
        })
      };

    const tbody = priceDataAverage ? (priceDataAverage.map((item, index) => {
    return <tr key={index}>
            {(groupName === 'hour_year' || groupName === 'five_min_year' || groupName === 'hour_year_month' || groupName === 'five_min_year_month' || groupName === 'hour_year_wint_sum' || groupName === 'five_min_year_wint_sum')
                && <td>{item.year}</td>}
            {(groupName === 'hour_month' || groupName === 'five_min_month' || groupName === 'hour_year_month' || groupName === 'five_min_year_month')
                && <td>{months[item.month ? item.month -1 : 0]}</td>}
            {(groupName === 'hour_wint_sum' || groupName === 'five_min_wint_sum' || groupName === 'hour_year_wint_sum' || groupName === 'five_min_year_wint_sum')
                && <td>{wint_sum[item.wint_summ ? item.wint_summ : 0]}</td>}
            <td>{formatCurrency(item.price, "USD", 2)}</td>
            <td>{item.name}</td>
        </tr>
    }
    )) : null;
    
    return (
        <Loading isLoading={isLoadingChart} errorMessage={errorMessage}>
            <>
            <div className="tabContainer" >
                <div className={classes.sectionTitleWithHelpCenter}>
                    <div className={classes.sectionTitleNoMargin}><b>Price Averages</b></div>
                    <HelpHover messageList={helpPrices} largeMarg={false}></HelpHover>
                </div> 
                <div className="flex-container-center">

                    <div className="paddedNotBottom margin-top-pos-xsmall">
                        <DefaultButton title="Peak" disabled={peakHasError} onClick={toggleOnOffPeaks} primary={true} ></DefaultButton>
                    </div>
                    <div className="paddedNotBottom margin-top-neg-small">
                        <label htmlFor="peakStart" className="labelSmall" >Peak Start</label>
                        <Select
                            id="peakStart"
                            onChange={handlerStartPeakInput}
                            options={hoursOptions}
                            value={selectedStart}
                            className='react-select-container'
                            classNamePrefix="react-select"
                            menuPlacement='auto'
                            styles={peakStyles}
                        />
                    </div>
                    <div className="paddedNotBottom margin-top-neg-small">
                        <label htmlFor="peakEnd" className="labelSmall" >Peak End</label>
                        <Select
                            id="peakEnd"
                            onChange={handlerEndPeakInput}
                            options={hoursOptions}
                            value={selectedEnd}
                            className='react-select-container'
                            classNamePrefix="react-select"
                            menuPlacement='auto'
                            styles={peakStyles}
                        />
                    </div>
                    <div className="paddedNotBottom margin-top-pos-xsmall">
                        <DefaultButton title="Labels" disabled={false} onClick={updateChartLabels} primary={true} ></DefaultButton>
                    </div>                   
                </div>
            </div>
            <div className="chartGroup">
                <AYPALineChart
                    config={chartConfig}
                    data={lineData}
                    mode={mode}
                    onCompleteCallback={() => onCompleteChartRender}
                    labels={chartLabels}
                    />
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            {(groupName === 'hour_year' || groupName === 'five_min_year' || groupName === 'hour_year_month' || groupName === 'five_min_year_month' || groupName === 'hour_year_wint_sum' || groupName === 'five_min_year_wint_sum')
                                && <th>Year</th>}
                            {(groupName === 'hour_month' || groupName === 'five_min_month' || groupName === 'hour_year_month' || groupName === 'five_min_year_month')
                                && <th>Month</th>}
                            {(groupName === 'hour_wint_sum' || groupName === 'five_min_wint_sum' || groupName === 'hour_year_wint_sum' || groupName === 'five_min_year_wint_sum')
                                && <th>Season</th>}
                            <th>Average Price</th>
                            <th>Forecast/Stream</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbody}
                    </tbody>
                </Table>
            </div>
            </>

        </Loading>
    )
}
