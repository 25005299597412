import { components } from 'react-select'
import sprites from "common/assets/img/sprites.svg";
import { DateTime } from 'luxon';

export const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg>
                <use xlinkHref={`${sprites}#${props.selectProps.inputProps.icon}`}></use>
            </svg>
        </components.DropdownIndicator>
    );
};

export const CustomOption = (props: any) => {
    return (
        <components.Option {...props}>
            {props.data.longLabel}
        </components.Option>
    );
};

export const PriceOption = (props: any) => {

    const minDate = DateTime.fromISO(props.data.min_timestamp).toLocaleString(DateTime.DATE_MED)
    const maxDate = DateTime.fromISO(props.data.max_timestamp).toLocaleString(DateTime.DATE_MED)

    return (
        <components.Option {...props}>
            <div>
                <div><b>{props.data.label}</b></div>
                <div>{props.data.granularity}&nbsp;-&nbsp;{props.data.price_type}</div>
                <div>{minDate}&nbsp;-&nbsp;{maxDate}</div>
                <div className="color-grey-1">{props.data.description}</div>
            </div>
        </components.Option>
    );
};

export const ForecastOption = (props: any) => {

    const minDate = DateTime.fromISO(props.data.min_timestamp).toLocaleString(DateTime.DATE_MED)
    const maxDate = DateTime.fromISO(props.data.max_timestamp).toLocaleString(DateTime.DATE_MED)
    const createdDate = DateTime.fromISO(props.data.timestamp_created).toLocaleString(DateTime.DATETIME_MED)   
    return (
        <components.Option {...props}>
            <div>
                <div>
                    <div><b>{props.data.label}</b></div>
                </div>
                <div>{props.data.inflation_vector}</div>
                <div>{minDate}&nbsp;-&nbsp;{maxDate}</div>
                <div className="color-grey-1">
                    Uploaded {createdDate}
                </div>
            </div>
        </components.Option>
    );
};

export const customStyles = {
    menu: (css: any) => ({
        ...css,
        left: "-16rem"
    })
}

export const customStylesWithPadding = {
    menu: (css: any) => ({
        ...css,
        left: "-16rem"
    }),
    singleValue: (css: any) => ({
        ...css
    })
}
