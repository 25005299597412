import { ApplicationState } from "store";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { operationsService } from "common/adapters/operations";
import { setSimulateButton } from "pages/home/stores/slices/settings-slice";
import { successOperation, requestedDoneWithErrorOperation } from "pages/home/stores/slices/chart-slice";

export const useExecuteOperation = () => {
    /**Contants */
    const dispatch = useDispatch();
    // 1sec for price lookups, 5sec for simulations
    const intervalTime = 5000;

    /**States */
    const [intervalId, setIntervalId] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<any>(null);

    /**Selectors */
    const chartReducer = useSelector((store: ApplicationState) => store.chartReducer);

    const operationId = chartReducer.operationId;

    const startInterval = () => {

        const id = setInterval(() => {
            operationsService(operationId)
                .then((response: any) => {
                    if (response.data.done) {

                        const isAnError = responseIsAnError(response.data);
                        if (isAnError) {
                            responseDoneWithError(response.data);
                        }
                        else {
                            responseWithoutError(response.data);
                        }
                    }
                }).catch((error: any) => {
                    setErrorMessage(error);
                });
        }, intervalTime);
        setIntervalId(id);
    }

    const stopInterval = () => {
        clearInterval(intervalId);
        setIntervalId(null);
    }

    const responseIsAnError = (data: any) => {
        const isError = data.result.message || data.result.details || data.result.error;
        return isError;
    }

    const responseDoneWithError = (data: any) => {
        const message = data.result.error?.message;
        const details = data.result.error?.details;
        setErrorMessage({ message, details });
        dispatch(requestedDoneWithErrorOperation({ message, details }));
        dispatch(setSimulateButton(false));

    }

    // TODO update on success to call prices again
    const responseWithoutError = (data: any) => {
        dispatch(successOperation(data));
    }

    useEffect(() => {
        if (errorMessage) {
            stopInterval();
        }

        return () => {
            clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage]);

    useEffect(() => {
        const executeOperation = chartReducer.executeOperation;
        const isDone = chartReducer.isDone;
        if (executeOperation) {
            startInterval();
        }

        if (isDone || !executeOperation) {
            stopInterval();
        }

        return () => {
            clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartReducer.executeOperation, chartReducer.isDone]);

    return { errorMessage };
}
