import { Table } from "reactstrap";
import { Loading } from "../../components/loading";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { SummaryForesightData } from "common/interfaces/perfectForesightData";
import { useEffect, useState } from "react";
import classes from "./../../style.module.scss";
import { formatCurrency } from "common/utils/utils";
import { DateTime } from "luxon";
import { HelpHover } from "common/components/helpButton";

export const RevenuesSummaryTable = () => {
    const initHelp = ["This is the first 2 days of Perfect Foresight.",
                    "The timestamps are based off the sourced data.",
                    "Start and End SOC are in MW"]
    const isLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);
    const perfectForesightSummaryData = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesightSummaryData);
    const perfectForesightId = useSelector((store: ApplicationState) => store.chartReducer.perfectForesightId);

    const [revenues, setRevenues] = useState<Array<SummaryForesightData>>();

    const tbody = revenues ? (revenues.map((item, index) => (
        <tr key={index}>
            <td>{DateTime.fromISO(item.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}</td>
            <td>{formatCurrency(item.price)}</td>
            <td>{item.buy.toFixed(2)}</td>
            <td>{item.sell.toFixed(2)}</td>
            <td>{item.start_soc.toFixed(2)}</td>
            <td>{item.end_soc.toFixed(2)}</td>
            <td>{item.end_soc_pct.toFixed(2)}</td>
            <td>{formatCurrency(item.revenue)}</td>
            <td>{item.cycles.toFixed(2)}</td>
        </tr>
    ))) : null;

    useEffect(() => {
        if (perfectForesightId && perfectForesightSummaryData) {
            setRevenues(perfectForesightSummaryData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfectForesightId])

    return (
        <>
            <div className={classes.scheduleSearchSection}>
                <div className={classes.sectionTitleWithHelp}>
                    <div className={classes.sectionTitle}><b>Perfect Foresight Snapshot</b></div>
                    <HelpHover messageList={initHelp} largeMarg={false}></HelpHover>
                </div>
                <div className={classes.tableContainer}>
                    <Loading isLoading={isLoading} errorMessage={errorMessage}>
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Timestamp</th>
                                    <th>Price</th>
                                    <th>Buy MW</th>
                                    <th>Sell MW</th>
                                    <th>Start SOC</th>
                                    <th>End SOC</th>
                                    <th>End SOC %</th>
                                    <th>Revenue</th>
                                    <th>Cycles</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </Table>
                    </Loading>
                </div>
            </div>
        </>
    )
}