import classes from "./loading.module.scss";
import sprites from "common/assets/img/sprites.svg";

interface Props {
  isLoading: boolean,
  errorMessage: any | null,
  loadingMessage?: any,
  children?: JSX.Element,
  callback?: Function,
  showButtonCancel?: boolean
}
export const Loading = ({ isLoading, errorMessage, loadingMessage, children, showButtonCancel, callback }: Props) => {
  const executeCallback = () => {
    if (callback) {
      callback();
    }
  }

  return (
    <>
      {
        isLoading &&
        <div className={`${classes.loading} loader`}>
          <div className={classes.loading__spinner}>
            <svg>
              <use xlinkHref={`${sprites}#oval`}></use>
            </svg>
          </div>
          <div className={classes.loading__text}>
            {loadingMessage}
            {!loadingMessage &&
              <p>
                <b>Loading...</b>
              </p>
            }
          </div>
          <div className={classes.loading__cta}>
            {showButtonCancel && callback && <button onClick={() => executeCallback()}>Cancel</button>}
          </div>
        </div>
      }

      {
        errorMessage &&
        <div className={classes.loading}>
          <div className={classes.loading__warning}>
            <svg>
              <use xlinkHref={`${sprites}#warning`}></use>
            </svg>
          </div>
          <div className={classes.loading__text}>
            <p className={classes.loading__textgrey}> {errorMessage.message ?? errorMessage.title} {errorMessage.details ?? ''} </p>
          </div>
          <div className={classes.loading__try}>
            Please try again.
          </div>
        </div>
      }

      {
        !isLoading && !errorMessage && children
      }
    </>
  )
}
