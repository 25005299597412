import config from 'config';
import {api} from './api';

export const getPvSystem = (parameter?: string) => {
    
    let params = new URLSearchParams();
    params.append("limit", '100');
    params.append("offset", '0');

    if (parameter) {
        params.append("display_name", parameter);
    }
    
    api.defaults.baseURL = config.pvServiceBaseURL;
    return api.get(`pv-systems/`, { params });
}
