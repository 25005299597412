import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { RevenuesTable } from "./revenuesTable";
import classes from "./../../style.module.scss";
import { RevenuesChart } from "./revenuesChart";
import { HelpHover } from "common/components/helpButton";

export const RevenuesTableTabs = () => {

    /**Constants */
    const initHelp = ["Perfect Foresight is PF", "Top Bottom is TB", "TB is based off the Duration", "kWm = kilowatt-months"]
    const isLoading = useSelector((store: ApplicationState) => store.tablesReducer.isLoading);

    /**UseStates */
    const [key, setKey] = useState('total');

    const setKeyHandler = (newkey: any) => {
        if (!isLoading) setKey(newkey);
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div className={classes.revenuesSection}>
            <div className={classes.sectionTitleWithHelp}>
                <div className={classes.sectionTitle}><b>Revenues</b></div>
                <HelpHover messageList={initHelp} largeMarg={false}></HelpHover>
            </div>


            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k: any) => setKeyHandler(k)}
                justify>
                <Tab eventKey="total" title="Total">
                    {key === 'total' &&
                        <div className={classes.tabTableContent}>
                            <RevenuesTable group={'total'} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="monthly" title="Monthly">
                    {key === 'monthly' &&
                        <div className={classes.tabTableContent}>
                            <div className={`${classes.tabContent} ${classes.oneChart}`}>
                                <RevenuesChart group={'month'} />
                            </div>
                            <RevenuesTable group={'monthly'} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="wint_sum" title="Seasonally">
                    {key === 'wint_sum' &&
                        <div className={classes.tabTableContent}>
                            <div className={`${classes.tabContent} ${classes.oneChart}`}>
                                <RevenuesChart group={'wint_sum'} />
                            </div>
                            <RevenuesTable group={'wint_sum'} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="yearly" title="Yearly">
                    {key === 'yearly' &&
                        <div className={classes.tabTableContent}>
                            <div className={`${classes.tabContent} ${classes.oneChart}`}>
                                <RevenuesChart group={'year'} />
                            </div>
                            <RevenuesTable group={'yearly'} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="yearly_wint_sum" title="Season by Year">
                    {key === 'yearly_wint_sum' &&
                        <div className={classes.tabTableContent}>
                            <div className={`${classes.tabContent} ${classes.twoCharts}`}>
                                <RevenuesChart group={'yearly_wint_sum'} />
                            </div>
                            <RevenuesTable group={'yearly_wint_sum'} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="yearly_monthly" title="Month by Year">
                    {key === 'yearly_monthly' &&
                        <div className={classes.tabTableContent}>
                            <div className={`${classes.tabContent} ${classes.twoCharts}`}>
                                <RevenuesChart group={'yearly_monthly'} />
                            </div>
                            <RevenuesTable group={'yearly_monthly'} />
                        </div>
                    }
                </Tab>
            </Tabs>
        </div>
    )
}
