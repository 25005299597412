import { createSlice } from "@reduxjs/toolkit";
import { SimulateChartState } from "../states/simulate-chart-state";


const initialState: SimulateChartState = {
    isLoading: false,
    errorMessage: null,
    isDone: false,
    operationId: null,
    fixedScheduleId: null,
    hybridRevenueId: null,
    executeOperation: false,
    executeRunFixedSchedule: false,
    executeFixedScheduleSummary: false,
    executeFixedScheduleSummaryComplete: false,
    executeFixedScheduleSummaryData: null,
    executeFixedScheduleSummaryError: null,
    executeHybridRevenue: false,
    executeHybridRevenueComplete: false,
    executeHybridRevenueData: null,
    executeHybridRevenueError: null,
    cancelOperation: false,
    isCSVDownloading: false,
    executeRunPerfectForesight: false,
    executePrepPerfectForesight: false,
    loadedData: "",
    completedPerfectForesight: false,
    perfectForesightId: null,
    completedDownloadCSV: false,
}

export const chartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {
        resetSimulation(state) {
            // on simulate button click 1st - reset everything
            Object.assign(state, initialState);
        },
        fetchRunFixedSchedule(state) {
            // on simulate button click 2nd - trigger new fixed schedule
            // everything already in initial state
            state.isLoading = true;
            state.executeRunFixedSchedule = true
        },
        fetchRunPerfectForesight(state) {
            // on simulate button click 2nd - trigger new perfect foresight
            // everything already in initial state
            state.isLoading = true;
            state.executePrepPerfectForesight = true
        },
        runPerfectForesight(state) {
            // after all data is loaded to call perfect foresight - trigger lookup
            state.executeRunPerfectForesight = true;
            state.executePrepPerfectForesight = false
        },
        successRunFixedSchedule(state, action) {
            // return values of fixed-schedule/:run - no errors
            const { operation_id, fixed_schedule_id, hybrid_revenue_id } = action.payload;
            const executeFixedScheduleSummary = !(fixed_schedule_id == null);
            const executeHybridRevenue = !(hybrid_revenue_id == null);
            const executeOperation = (executeFixedScheduleSummary === false && executeHybridRevenue === false);
            const isLoading = executeOperation;

            state.isLoading = isLoading;
            state.executeFixedScheduleSummary = executeFixedScheduleSummary;
            state.executeHybridRevenue = executeHybridRevenue;
            state.executeRunFixedSchedule = false;
            state.executeOperation = executeOperation;
            state.errorMessage = null;
            state.operationId = operation_id;
            state.fixedScheduleId = fixed_schedule_id;
            state.hybridRevenueId = hybrid_revenue_id;
            state.executeHybridRevenueComplete = false;
            state.executeFixedScheduleSummaryComplete = false;
            state.executeHybridRevenueData = null;
            state.executeHybridRevenueError = null;
        },
        failedRunFixedSchedule(state, action) {
            // fixed-schedule/:run - errors
            state.isLoading = false;
            state.executeRunFixedSchedule = false;
            state.errorMessage = action.payload
        },
        cancelOperationTimeOut(state) {
            // on fixed schedule timeout or cancel button - set cancelOperation to True
            Object.assign(state, initialState);
            state.cancelOperation = true;
        },
        successOperation(state, action) {
            // data returned from operations-service without errors for fixed schedule
            state.isLoading = false;
            state.executeOperation = false;
            state.executeFixedScheduleSummary = true;
            state.executeHybridRevenue = true;
            state.isDone = action.payload.done;
            state.fixedScheduleId = action.payload.result.fixed_schedule_id;
            state.hybridRevenueId = action.payload.result.hybrid_revenue_id
        },
        requestedDoneWithErrorOperation(state, action) {
            // operations-service errors for fixed schedule
            state.errorMessage = action.payload;
            state.isLoading = false;
            state.executeOperation = false
        },
        /** WebSocket Actions */  
        successRunPerfectForecast(state, action) {
            // on success websocket message returned that is not COMPLETED - append data
            state.isLoading = true;
            // TODO EE testing - update SimulationWrapperWebSocket.tsx too
            // if (state.loadedData === "") {
            //     state.loadedData += action.payload;
            // }
            state.loadedData += action.payload;
            
        },
        setPerfectForesightRequestCompleted(state) {
            // on success websocket message COMPLETE is returned
            state.isLoading = false;
            state.executeRunPerfectForesight = false;
            state.completedPerfectForesight = true
        },
        successOperationPerfectForesight(state, action) {
            // after COMPLETED is returned. save the parsed out the loaded data json
            state.perfectForesightId = action.payload;
            state.loadedData = "";
            state.completedPerfectForesight = false;
            state.isDone = true
        },
        successDownloadSimulateChart(state, action) {
            // on success message returned that is not COMPLETED - append data
            state.loadedData += action.payload;
        },
        setDownloadCompletedSimulateChart(state) {
            // once COMPLETE is returned for download
            state.completedDownloadCSV = true
        },
        setSimulateFail(state, action) {
            // Websocket error onMessage returned
            // Parsed JSON object has error
            // data to get perfect foresight failed (mean chart, tables, etc)
            // getting forecast key
            // if anything fails with perfect foresight, will set error message and must be run again.
            // TODO make calls using the perfectForesight ID have their own errors and ability to try again.
            state.isLoading = false;
            state.executeRunPerfectForesight = false;
            state.executePrepPerfectForesight = false;
            state.errorMessage = action.payload;
            state.loadedData = "";
            state.perfectForesightId = null;
            state.isCSVDownloading = false
        },
        /** Common Actions */
        setErrorMessage(state, action) {
            // Reset error when changing options on settings
            state.errorMessage = action.payload
        },
        setCSVDownloading(state, action) {
            // when Download Raw Data as csv button pushed
            state.isCSVDownloading = action.payload;
            state.loadedData = "";
            state.completedDownloadCSV = false
        },
        executeHybridRevenueComplete(state, action) {
            // when executeHybridRevenue has completed
            state.executeHybridRevenueComplete = true;
            state.executeHybridRevenueData = action.payload.data;
            state.executeHybridRevenueError = action.payload.error;
        },
        executeHybridRevenueCompleteLoaded(state) {
            // when executeHybridRevenue has been loaded, reset state
            state.executeHybridRevenueComplete = false;
        },
        executeFixedScheduleSummaryComplete(state, action) {
            // when executeFixedScheduleSummary has completed
            state.executeFixedScheduleSummaryComplete = true;
            state.executeFixedScheduleSummaryData = action.payload.data;
            state.executeFixedScheduleSummaryError = action.payload.error;
        }

    }
})

export const { resetSimulation, fetchRunFixedSchedule, fetchRunPerfectForesight,
    runPerfectForesight, successRunPerfectForecast, successRunFixedSchedule,
    failedRunFixedSchedule, cancelOperationTimeOut, successOperation,
    requestedDoneWithErrorOperation, successOperationPerfectForesight, successDownloadSimulateChart,
    setPerfectForesightRequestCompleted, setDownloadCompletedSimulateChart, setSimulateFail,
    setErrorMessage, setCSVDownloading, executeHybridRevenueComplete, executeHybridRevenueCompleteLoaded, executeFixedScheduleSummaryComplete } = chartSlice.actions;

export default chartSlice.reducer;