import { createSlice } from '@reduxjs/toolkit'
import { SettingState } from '../states/settings-state';
import { SourceTypes } from '../types/source-types';
import { PriceListData } from 'common/interfaces/priceListData';

export const INITAL_BATTERY_HOUR = 4;

const initialState: SettingState = {
    sourceId: SourceTypes.NRG_STREAM,
    sourceName: 'NRG Stream',
    isoId: null,
    isoName: null,
    nodeId: null,
    nodeName: null,
    nodeBus: null,
    regionId: null,
    regionName: null,
    forecastId: null,
    forecastName: null,
    inflationVector: null,
    forecastKey: null,
    locationId: null,
    locationName: null,
    priceId: null,
    priceMaxtimestamp: null,
    priceMintimestamp: null,
    dateRange: null,
    priceList: [],
    batteryPower: 100,
    batteryHour: INITAL_BATTERY_HOUR,
    roundTripEfficiency: 88,
    fixedSchedule: false,
    perfectForesight: false,
    system: null,
    configuration: null,
    priceTimezone: null,
    getPriceButtonclicked: false,
    simulateButtonclicked: false,
    priceChartBase64Image: null,
    simulationChartBase64Image: null,
    activeCard: '0',
    streamId: null,
    streamName: null,
    granularity: null,
    allPriceData: null,
    allRawPrices: null,
    priceDataCharts: {},
    priceDataRawCharts: {},
    nodeOffset: 0,
    nodeInputValue: '',
    perfectForesightMeanData: null,
    perfectForesightSumData: null,
    perfectForesightSummaryData: null,
    cycleCount: 1.0,
    priceTab: "overall"
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setActiveCard(state, action) {
            // on card change (Profile, Chart, Tables)
            state.activeCard = action.payload
        },
        setSource(state, action) {
            // When source dropdown is changed
            // reset everything but simulation inputs
            // pv8760 will get reset due to async drop downs
            const perfectForesight = state.perfectForesight;
            const fixedSchedule = state.fixedSchedule;
            const activeCard = state.activeCard;
            const batteryHour = state.batteryHour;
            const batteryPower = state.batteryPower;
            const roundTripEfficiency = state.roundTripEfficiency;
            const cycleCount = state.cycleCount;
            const priceList = state.priceList;

            Object.assign(state, initialState);

            state.sourceId = action.payload.sourceId;
            state.sourceName = action.payload.sourceName;
            state.perfectForesight = perfectForesight;
            state.fixedSchedule = fixedSchedule;
            state.activeCard = activeCard;
            state.batteryHour = batteryHour;
            state.batteryPower = batteryPower;
            state.roundTripEfficiency = roundTripEfficiency;
            state.cycleCount = cycleCount;
            state.priceList = priceList;
        },
        setIso(state, action) {
            // on source=NRG ISO changed
            // on nrg node set
            // reset everything except source
            const sourceId = state.sourceId;
            const sourceName = state.sourceName;
            const priceList = state.priceList;

            Object.assign(state, initialState);

            state.isoId = action.payload.isoId;
            state.isoName = action.payload.isoName;
            state.priceTimezone = action.payload.timezone;
            state.sourceId = sourceId;
            state.sourceName = sourceName;
            state.priceList = priceList;
        },
        setTimezone(state, action) {
            // source=nrg when node id change must set timezone after region ISO is set
            state.priceTimezone = action.payload
        },
        setNrgNode(state, action) {
            // on source=nrg node change
            // reset on set iso changed
            state.nodeId = action.payload.nodeId;
            state.nodeName = action.payload.nodeName;
            state.isoId = action.payload.isoId;
            state.isoName = action.payload.isoName;
            state.priceId = null;
            state.priceMaxtimestamp = null;
            state.priceMintimestamp = null;
            state.dateRange = null
        },
        setPrice(state, action) {
            // on source=nrg price change
            // reset on node change
            state.priceId = action.payload.priceId;
            state.dateRange = null;
            state.priceMaxtimestamp = action.payload.priceMaxtimestamp;
            state.priceMintimestamp = action.payload.priceMintimestamp;
            state.getPriceButtonclicked = false;
            state.simulateButtonclicked = false;
            state.streamId = action.payload.streamId;
            state.streamName = action.payload.name;
            state.granularity = action.payload.granularity
        },
        setPriceInterval(state, action) {
            // on source=nrg price internal drop down change
            // source=nrg on price id, iso, or node id change
            state.granularity = action.payload;
            state.getPriceButtonclicked = false;
            state.simulateButtonclicked = false
        },
        setRegionId(state, action) {
            // source= not nrg.  on region selection change
            // changes on source change
            state.regionId = action.payload.regionId;
            state.priceTimezone = action.payload.timezone;
            state.regionName = action.payload.regionName;
            state.dateRange = null
        },
        setLocationId(state, action) {
            // source= not nrg.  on location change - all but 
            // on region or forecast change
            // last entity of source=zonal/woodmac reset button clicked
            state.locationId = action.payload.locationId;
            state.locationName = action.payload.locationName;
            state.nodeId = null;
            state.nodeName = null;
            state.nodeBus = null;
            state.nodeOffset = 0;
            state.getPriceButtonclicked = false;
            state.simulateButtonclicked = false;
            state.forecastKey = null;
        },
        setForecastData(state, action) {
            // source= not nrg. On forecast selection change 
            // on region ID change
            state.forecastId = action.payload.id;
            state.dateRange = null;
            state.priceMintimestamp = action.payload.priceMinTimestamp;
            state.priceMaxtimestamp = action.payload.priceMaxTimestamp;
            state.nodeId = null;
            state.nodeName = null;
            state.nodeBus = null;
            state.forecastName = action.payload.name;
            state.inflationVector = action.payload.inflationVector;
            state.nodeOffset = 0;
            state.locationId = null;
            state.locationName = null
        },
        setNodalNode(state, action) {
            // on source=nodal node change
            // last entity of source=nodal reset button clicked
            state.nodeId = action.payload.nodeId;
            state.nodeName = action.payload.nodeName;
            state.nodeBus = action.payload.nodeBus;
            state.locationId = action.payload.locationId;
            state.locationName = action.payload.locationName;
            state.getPriceButtonclicked = false;
            state.simulateButtonclicked = false;
            state.forecastKey = null;
        },
        setNodeOffset(state, action) {
            // source=nrg when node is changed, user type, node list reset
            // source=nodal when node is changed, user type, node list reset
            state.nodeOffset = action.payload
        },
        setNodeInputValue(state, action) {
            // source=nrg when user type
            // source=nodal when user type
            state.nodeInputValue = action.payload
        },
        setDateRange(state, action) {
            // source=all on date range manual change
            state.dateRange = action.payload;
            state.getPriceButtonclicked = false;
            state.simulateButtonclicked = false
        },
        setFixedSchedule(state, action) {
            // Simulate Type Radio buttons change
            // must set other radio to false
            state.fixedSchedule = action.payload;
            state.perfectForesight = false;
            state.simulateButtonclicked = false
        },
        setPerfectForesight(state, action) {
            // Simulate Type Radio buttons change
            // must set other radio to false
            state.perfectForesight = action.payload;
            state.fixedSchedule = false;
            state.simulateButtonclicked = false
        },
        setBatteryPower(state, action) {
            // simulate= both on rated power change
            // reset simulation if changed
            state.batteryPower = action.payload;
            state.simulateButtonclicked = false
        },
        setBatteryHour(state, action) {
            // simulate= both on durationchange
            // reset simulation if changed
            state.batteryHour = action.payload;
            state.simulateButtonclicked = false
        },
        setRoundTripEfficiency(state, action) {
            // simulate=fixed schedule on RTE change
            // reset simulation if changed
            state.roundTripEfficiency = action.payload;
            state.simulateButtonclicked = false
        },
        setSystem(state, action) {
            // simulate=fixed schedule on PV 8760 system change
            state.system = action.payload
        },
        setConfiguration(state, action) {
            // simulate=fixed schedule on PV 8760 configuration change -
            // last entity reset button clicked
            state.configuration = action.payload;
            state.simulateButtonclicked = false
        },
        setCycleCount(state, action) {
            // simulate=perfect foresight.  when cycle count changed
            state.cycleCount = action.payload;
            state.simulateButtonclicked = false
        },
        setGetPriceButton(state, action) {
            // on Get Price button click
            // on any error in the price lookup process
            state.getPriceButtonclicked = action.payload;
            state.activeCard = '0';
            state.priceChartBase64Image = null;
            state.priceDataCharts = {};
            state.forecastKey = null;
        },
        setSimulateButton(state, action) {
            // on Simulate button click
            // on any error in the simulate process
            state.simulateButtonclicked = action.payload;
            state.activeCard = '1';
            state.simulationChartBase64Image = null;
            state.perfectForesightMeanData = null;
            state.perfectForesightSumData = null;
            state.perfectForesightSummaryData = null
        },
        setPriceData(state, action) {
            // after price lookup completed, set formatted chart data
            // could have forecast or nrg data, reset the one it isn't
            const forecastKey = action.payload.key;
            state.priceDataCharts[action.payload.eventId] = action.payload.data;
            state.forecastKey = forecastKey;
            state.streamId = forecastKey ? null : state.streamId;
            state.granularity = forecastKey ? null : state.granularity
        },
        setPriceRawData(state, action) {
            // after price lookup raw completed, set formatted chart data
            // could have forecast or nrg data, reset the one it isn't
            const forecastKey = action.payload.key;
            state.priceDataRawCharts[action.payload.eventId] = action.payload.data;
            state.forecastKey = forecastKey;
            state.streamId = forecastKey ? null : state.streamId;
            state.granularity = forecastKey ? null : state.granularity;
        },
        setForecastKey(state, action) {
            // after getting forecast key is completed. 
            state.forecastKey = action.payload;
            state.streamId = null;
            state.granularity = null
        },
        setPerfectForesightData(state, action) {
            // after perfect foresight completed, set all formatted data
            // TODO add revenue data and snapshot data, reset to null on SET_SIMULATEBUTTON
            state.perfectForesightMeanData = action.payload.meanData;
            state.perfectForesightSumData = action.payload.sumData;
            state.perfectForesightSummaryData = action.payload.summaryData
        },
        setPriceImage(state, action) {
            // after data returned is converted to image
            // reset to null on price button
            state.priceChartBase64Image = action.payload
        },
        setDisplay(state, action) {
            // reset price image when changing tabs on graph
            state.priceChartBase64Image = null;
            state.priceTab = action.payload;
        },
        setSimulationImage(state, action) {
            // after data returned is converted to image
            // reset to null on simulate button
            state.simulationChartBase64Image = action.payload
        },
        setSimulationDisplay(state) {
            // reset simulate image when changing tabs on graph
            state.simulationChartBase64Image = null
        },
        addPriceList(state) {
            // adds item to price list
            let eventId = state.sourceId + "_";
            eventId = state.regionId ? eventId + state.regionId + "_" : eventId + state.isoId + "_";
            const regionName = state.regionName ? state.regionName : state.isoName
            const nameList = [state.sourceName, regionName]
            if (state.forecastName) {
                nameList.push(state.forecastName);
                eventId += state.forecastId + "_";
            }
            // last thing appeneded will be the label on the graph.
            if (state.locationName) {
                nameList.push(state.locationName);
                eventId += state.locationId + "_";
            }
            if (state.nodeName) {
                nameList.push(state.nodeName);
                eventId += state.nodeId + "_";
            }
            if (state.streamName) {
                nameList.push(state.streamName);
                eventId += state.streamId + "_";
            }
            // only add new items and no more than 10 and can't mix 5min and hourly granularities
            if (state.priceList.length < 10 && !state.priceList.some(obj => (obj.eventId === eventId) || 
                ((state.granularity === "FIVEMIN" && obj.granularity !== "FIVEMIN") || (state.granularity !== "FIVEMIN" && obj.granularity === "FIVEMIN")))
            ) {
                const newPrice: PriceListData = {
                    eventId: eventId,
                    name: nameList.join(' | '),
                    sourceName: state.sourceName,
                    sourceId: state.sourceId,
                    isoId: state.isoId,
                    isoName: state.isoName,
                    regionName: state.regionName,
                    regionId: state.regionId,
                    locationName: state.locationName,
                    locationId: state.locationId,
                    nodeName:state.nodeName,
                    nodeId: state.nodeId,
                    nodeBus: state.nodeBus,
                    priceId: state.priceId,
                    streamName: state.streamName,
                    streamId: state.streamId,
                    forecastName: state.forecastName,
                    forecastId: state.forecastId,
                    granularity: state.granularity,
                    priceMaxtimestamp: state.priceMaxtimestamp,
                    priceMintimestamp: state.priceMintimestamp,
                    dateRange: state.dateRange,
                    inflationVector: state.inflationVector
                }
                state.priceList.push(newPrice);
                state.getPriceButtonclicked = false;
                state.simulateButtonclicked = false;
            }
        },
        removePriceList(state,action) {
            // on removing item from price list
            // this resets price settings to init too - clear buttons and chart data
            state.priceList = state.priceList.filter(obj => obj.eventId !== action.payload);
            state.getPriceButtonclicked = false;
            state.simulateButtonclicked = false;
            state.priceDataCharts = {};
            state.priceDataRawCharts = {};
        },
        priceDataRawFailed(state) {
            // on websocket error in the price lookup raw process
            state.priceChartBase64Image = null;
            state.priceDataRawCharts = {};
            state.allRawPrices = null;
        },

    },
});

export const { setActiveCard, setSource,
    setIso, setTimezone, setNrgNode, setPrice,
    setPriceInterval, setRegionId, setLocationId,
    setForecastData, setNodalNode, setNodeInputValue, setNodeOffset,
    setDateRange, setFixedSchedule, setPerfectForesight, setBatteryPower,
    setBatteryHour, setRoundTripEfficiency, setSystem,
    setConfiguration, setCycleCount, setGetPriceButton,
    setSimulateButton, setPriceData, setPriceRawData, setForecastKey, setPerfectForesightData,
    setPriceImage, setDisplay, setSimulationDisplay, setSimulationImage, addPriceList,
    removePriceList, priceDataRawFailed } = settingsSlice.actions;

export default settingsSlice.reducer;