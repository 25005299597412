import Select from 'react-select';
import { ApplicationState } from "store";
import AsyncSelect from 'react-select/async';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPvSystem } from "common/adapters/pvSystems";
import { styleFlex, styleFlexColumn } from "common/components/style";
import { getPvConfiguration } from "common/adapters/pvConfigurations";
import classes from 'common/components/divContainer/style.module.scss';
import { customStyles } from "common/components/selectComponents";
import { setConfiguration, setSystem } from "pages/home/stores/slices/settings-slice";
import { DivContainer, DivContainer100, DivContainer50 } from "common/components/divContainer";

interface Props {
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const PV8760 = ({ isLoadingEnabled, isEntitySelected }: Props) => {

    /**CONSTANTS */
    let selectRefConfig: any = null;
    const dispatch = useDispatch();

    /**STATES */
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isDisabledConfiguration, setIsDisabledConfiguration] = useState<boolean>(false);
    const [configurationList, setConfigurationList] = useState<Array<any>>([]);

    /**SELECTORS */
    const settings = useSelector((store: ApplicationState) => store.settingsReducer);


    /**USEEFFECTS */
    useEffect(() => {
        if (isEntitySelected && settings.batteryPower && settings.fixedSchedule ) {
            setIsDisabled(false);
            setIsDisabledConfiguration(false);
        } else {
            setIsDisabled(true);
            setIsDisabledConfiguration(true);
        }
    }, [
        isEntitySelected,
        settings.batteryPower,
        settings.fixedSchedule
    ]);

    useEffect(() => {
        if (settings.system) {
            loadOptionsConfigurations(settings.system);
        }
    }, [settings.system]);


    const mapData = (data: Array<any>) => {
        return data.map((s: any) => {
            const label =
                <div className={classes.labelContainer}>
                    <div className={classes.labelContainer__name}>
                        <div className={classes.labelContainer__left}>{s.display_name}</div>
                        <div className={classes.labelContainer__right}></div>
                    </div>
                </div>
                ;
            return { value: s.id, label: label };
        });
    }

    const loadOptionsSystem = (inputValue: string, callBack: Function) => {
        return getPvSystem(inputValue).then((response: any) => {
            return mapData(response.data.items);
        });
    }

    const loadOptionsConfigurations = (value: any) => {
        getPvConfiguration(value).then((response: any) => {
            const data = response.data.map((s: any) => { return { value: s.id, label: s.display_name } })
            setConfigurationList(data);
        })
    }

    const handlerOnInputChangeSystem = (option: any, action: any) => {

        if (action.action === 'select-option') {
            actionSelectOptionChangeSystem(option);
        }

        if (action.action === 'clear') {
            actionClearChangeSystem();
        }
    };

    const actionSelectOptionChangeSystem = (option: any) => {
        dispatch(setSystem(option.value));
        setIsDisabledConfiguration(false);
    }

    const actionClearChangeSystem = () => {
        dispatch(setSystem(null));
        setConfigurationList([]);
        setIsDisabledConfiguration(true);
        selectRefConfig.clearValue();
    }

    const handlerOnChangeConfiguration = (option: any) => {
        dispatch(setConfiguration(option?.value));
    }

    return (
        <DivContainer style={styleFlexColumn}>
            <>
                <DivContainer100><label><b>PV 8760 (optional)</b></label></DivContainer100>

                <DivContainer100 style={styleFlex}>
                    <>
                        <DivContainer50 label="System">
                            <AsyncSelect
                                id='pvSystemSelector'
                                key={JSON.stringify('1')}
                                defaultOptions
                                loadOptions={loadOptionsSystem}
                                onChange={handlerOnInputChangeSystem}
                                className='react-select-container'
                                classNamePrefix="react-select"
                                isDisabled={isDisabled || isLoadingEnabled}
                                isClearable
                                menuPlacement='top'
                            />
                        </DivContainer50>

                        <DivContainer50 label="Configuration">
                            <Select
                                id='pvConfigurationSelector'
                                ref={ref => {
                                    selectRefConfig = ref;
                                }}
                                className='react-select-container'
                                classNamePrefix="react-select"
                                styles={customStyles}
                                options={configurationList}
                                isDisabled={isDisabledConfiguration || isLoadingEnabled}
                                onChange={handlerOnChangeConfiguration}
                                menuPlacement='top'
                            />
                        </DivContainer50>
                    </>
                </DivContainer100>
            </>
        </DivContainer>
    )
}

export default PV8760;
