
type RadioButtonProps = {
    id: string,
    name: string,
    value: string,
    label: string,
    disabled: boolean,
    checked: boolean,
    callback: Function
}

export const RadioButton = ({ id, name, value, label, disabled, checked, callback }: RadioButtonProps) => {
    const handleChange = (e: any) => {
        callback({ name: e.target.name, value: e.target.value });
    }

    return (
        <>
            <div className="radio-item">
                <input type="radio"
                disabled={disabled}
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={handleChange} />
                <label htmlFor={id}>{label}</label>
            </div>
        </>
    )
}