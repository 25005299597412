import { DivContainer, DivContainer100, DivContainer50 } from "common/components/divContainer";
import { Forecast } from "../sharedSourceComponents/forecast";
import { Region } from "../sharedSourceComponents/region";
import { Location } from "../sharedSourceComponents/location";

interface Props {
    isLoadingEnabled: boolean,
    showLocation: boolean
}
export const Zonal = ({ isLoadingEnabled, showLocation }: Props) => {

    /**Constants */
    const labelTitleRegion = "Region";
    const labelTitleForecast = "Forecast";
    const labelTitleLocation = "Location";

    return (
        <>

            <DivContainer>
                <>
                    <DivContainer50 label={labelTitleRegion}>
                        <Region isLoadingEnabled={isLoadingEnabled} />
                    </DivContainer50>

                    <DivContainer50 label={labelTitleForecast}>
                        <Forecast isLoadingEnabled={isLoadingEnabled} />
                    </DivContainer50>
                </>
            </DivContainer>
            {showLocation && (
                <DivContainer>
                    <>
                        <DivContainer100 label={labelTitleLocation}>
                            <Location isLoadingEnabled={isLoadingEnabled}/>
                        </DivContainer100>
                    </>
                </DivContainer>
            )}

        </>
    )
}
