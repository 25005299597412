export enum SourceTypes {
    AYPA_NODAL = 1,
    AYPA_ZONAL = 2,
    NRG_STREAM = 3,
    WOOD_MAC = 4
}

export function useNodesInSource(sourceId: number) {
    if (sourceId === SourceTypes.AYPA_NODAL || sourceId === SourceTypes.NRG_STREAM) {
        return true
    } else {
        return false
    }
  }

export const isEntitySelectedForSource = (sourceId: number, priceIdSelected: number | null, nodeIdSelected: number | null, locationIdSelected: number | null) => {
    switch (sourceId) {
        case SourceTypes.NRG_STREAM:
            return priceIdSelected !== null;
        case SourceTypes.AYPA_NODAL:
            return nodeIdSelected !== null;
        case SourceTypes.AYPA_ZONAL:
            return locationIdSelected !== null
        case SourceTypes.WOOD_MAC:
            return locationIdSelected !== null
        default:
            return false
    }
}