import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { Loading } from "../components/loading";
import { FixedScheduleTable } from "./fixedScheduleTable";
import { PerfectForesightTable } from "./perfectForesightTable";

export const SimulationTableWrapper = () => {

    /**Selectors */
    const isLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);
    const fixedSchedule = useSelector((store: ApplicationState) => store.settingsReducer.fixedSchedule);
    const perfectForesight = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesight);

    const loadingMessage =
        <p>
            <b>Generating Results</b><br />
            This may take up to 7 minutes...
        </p>

    return (
        <Loading isLoading={isLoading} errorMessage={errorMessage} loadingMessage={loadingMessage} >
            <div className="scrollable">
                {fixedSchedule && <FixedScheduleTable />}
                {perfectForesight && <PerfectForesightTable />}
            </div>
        </Loading>
    );
}
