import classes from './style.module.scss';
import { MainSection } from './mainSection';
import { SettingsSection } from './settingsSection';
import { useAuth } from 'common/hooks/useAuth';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { wsConnect } from './stores/actions/websocket-action';
import config from 'config';
import { useAxiosInterceptors } from 'common/adapters/api';

interface HomeProps {
    getAypaToken: Function
}

export const HomePage = ({ getAypaToken }: HomeProps) => {
    // sets function for api to retry if token is expired
    useAxiosInterceptors(getAypaToken);
    const { isValid, getToken } = useAuth({ getAypaToken });
    const dispatch = useDispatch();

    useEffect(() => {
        if (isValid) {
            dispatch(wsConnect(config.priceWs));
            dispatch(wsConnect(config.fixedScheduleWs));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const checkAypaToken = async () => {
        // console.log('check aypa token')
        await getToken();
      };

    return (
        <>
            { isValid &&
                <>
                    <div className={classes.settings}>
                        <SettingsSection />
                    </div>

                    <div className={classes.render}>
                        <MainSection checkAypaToken={checkAypaToken}/>
                    </div>
                </>
            }
        </>
    )
}
