import sprites from "common/assets/img/sprites.svg";
import { downloadChartImage } from "common/utils/utils";


import classes from "./../../style.module.scss";

interface Props {
    chartBase64Image: string,
    filename: string
}
export const ButtonDownloadAsPng = ({chartBase64Image, filename}: Props) => {
    /**CONSTANTS */
    const titleButton = 'Download as .PNG';

    return (
        <div className={classes.card__dropdownitem}>
            <button
                disabled={!chartBase64Image}
                onClick={() => downloadChartImage(chartBase64Image, filename)}>
                <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-export`}></use>
                    </svg>
                </span>
                {titleButton}
            </button>
        </div>
    )
}
