import { Table } from "reactstrap";
import { Loading } from "../../components/loading";
import { useHybridRevenuesTable } from "../../hooks/useHybridRevenuesTable";

interface tableProps {
    group: Array<string>
}
export const RevenuesTable = ({ group }: tableProps) => {

    const { isLoading, hybridRevenues, errorMessage } = useHybridRevenuesTable({ group });
    const format = (number: number, decimal: number) => {
        return new Intl.NumberFormat(
            'en-US', { style: 'decimal', maximumFractionDigits: decimal, minimumFractionDigits: decimal }
        ).format(number);
    }

    const hasYear = hybridRevenues.some(function (item) {
        return !!item.year;
    });

    const hasQuarter = hybridRevenues.some(function (item) {
        return !!item.quarter;
    });

    const hasMonth = hybridRevenues.some(function (item) {
        return !!item.month;
    });
    // console.log("hybridRevenues", hybridRevenues)

    const tbody = [];
    let i = 0;
    for (const item of hybridRevenues) {
        tbody.push(<tr key={'id' + i}>
            {hasYear && <td>{item.year}</td>}
            {hasMonth && <td>{item.month}</td>}
            {hasQuarter && <td>{item.quarter}</td>}
            <td>{format(item.generation, 0)}</td>
            <td>{format(item.system_grid, 0)}</td>
            <td>{format(item.generation_revenue, 0)}</td>
            <td>{format(item.system_revenue, 0)}</td>
            <td>{format(item.generation_revenue_per_mwh, 2)}</td>
            <td>{format(item.system_revenue_per_mwh_generation, 2)}</td>
            <td>{format(item.bess_value, 2)}</td>
            <td>{format(item.bess_value_per_mw_month, 0)}</td>
        </tr>);
        i++;
    }

    return (
        <Loading isLoading={isLoading} errorMessage={errorMessage}>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        {hasYear && <th>Year</th>}
                        {hasMonth && <th>Month</th>}
                        {hasQuarter && <th>Quarter</th>}
                        <th>PV<br />MWh</th>
                        <th>System<br />MWh</th>
                        <th>PV<br />$</th>
                        <th>System<br />$</th>
                        <th>PV<br />$/MWh</th>
                        <th>System<br />$/MWh</th>
                        <th>BESS<br />$/MWh</th>
                        <th>BESS Power Cap<br />$/MW/month</th>
                    </tr>
                </thead>
                <tbody>
                    {tbody}
                </tbody>
            </Table>
        </Loading>
    )
}
