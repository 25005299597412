import DefaultButton from 'common/components/button';
import classes from './../style.module.scss';
import { useDispatch } from 'react-redux';
import { addPriceList } from 'pages/home/stores/slices/settings-slice';
import { DivContainer } from 'common/components/divContainer';


interface Props {
    disableGetPrice: boolean,
    isEntitySelected: boolean
}
export const AddToPriceListButton = ({disableGetPrice, isEntitySelected}: Props) => {
    const dispatch = useDispatch();
    
    function onClick() {
        dispatch(addPriceList());
    }
    
    return (
        <>
        <DivContainer>
        <div className={classes.cta__button}>
            <DefaultButton  title="Add" disabled={disableGetPrice || !isEntitySelected} onClick={onClick} primary={true} />
        </div>
        </DivContainer>
        </>
    );
}
