import classes from "./modal.module.scss"
import Modal from 'react-bootstrap/Modal';
import DefaultButton from "../button";

interface Props {
    isOpen: boolean,
    onConfirm: Function,
    onClose: Function,
    text: string
}

function ConfirmationModal({ isOpen, onConfirm, onClose, text}: Props) {
    if (!isOpen) return null;
  
    const handleModalClick = (event: any) => {
        if (event.target === event.currentTarget) {
          // If the click is on the modal overlay (outside the modal content), close the modal
          onClose();
        }
      };

    return (
        <div className={classes.aypaModal} onClick={handleModalClick}> 
            <Modal.Dialog> 
                <Modal.Body> 
                    <p>{text}</p> 
                </Modal.Body> 
                <Modal.Footer>
                    <DefaultButton primary={true} title="Yes" disabled={false} onClick={() => onConfirm(true)} ></DefaultButton>
                    <DefaultButton primary={false} title="No" disabled={false} onClick={() => onConfirm(false)} ></DefaultButton>
                </Modal.Footer> 
            </Modal.Dialog> 
        </div> 
    );
  }

export default ConfirmationModal;
