import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { RevenuesTable } from "./revenuesTable";
import classes from "./../../style.module.scss";

export const RevenuesTableTabs = () => {

    /**Constants */
    const isLoading = useSelector((store: ApplicationState) => store.tablesReducer.isLoading);

    /**UseStates */
    const [key, setKey] = useState('total');

    const setKeyHandler = (newkey: any) => {
        if (!isLoading) setKey(newkey);
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div className={classes.revenuesSection}>
            <div className={classes.sectionTitle}><b>Revenues</b></div>

            <Tabs
                id="revenues-tabs"
                activeKey={key}
                onSelect={(k: any) => setKeyHandler(k)}
                justify>
                <Tab eventKey="total" title="Total">
                    {key === 'total' &&
                        <div className={classes.tabTableContent}>
                            <RevenuesTable group={[]} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="monthly" title="Monthly">
                    {key === 'monthly' &&
                        <div className={classes.tabTableContent}>
                            <RevenuesTable group={['year', 'month']} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="quarterly" title="Quarterly">
                    {key === 'quarterly' &&
                        <div className={classes.tabTableContent}>
                            <RevenuesTable group={['year', 'quarter']} />
                        </div>
                    }
                </Tab>
                <Tab eventKey="yearly" title="Yearly">
                    {key === 'yearly' &&
                        <div className={classes.tabTableContent}>
                            <RevenuesTable group={['year']} />
                        </div>
                    }
                </Tab>
            </Tabs>
        </div>
    )
}
