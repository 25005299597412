import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";


export const MessageErrorDateRange = ({dateRangeErr, dateRangeErrSim}: any) => {

    /**UseState */
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrMessage] = useState<string>("TEST");

    /**Selectors */
    const dateRange = useSelector((store: ApplicationState) => store.settingsReducer.dateRange);
    const priceMintimestamp = useSelector((store: ApplicationState) => store.settingsReducer.priceMintimestamp);
    const priceMaxtimestamp = useSelector((store: ApplicationState) => store.settingsReducer.priceMaxtimestamp);
    const granularity = useSelector((store: ApplicationState) => store.settingsReducer.granularity);
    const fixedSchedule = useSelector((store: ApplicationState) => store.settingsReducer.fixedSchedule);

    /**Constants */

    const validate = () => {

        let showErrPrice: boolean = false;
        let showErrSim: boolean = false;
        const startDate = dateRange ? dateRange[0] : priceMintimestamp;
        const endDate = dateRange ? dateRange[1] : priceMaxtimestamp;
        if (granularity === "FIVEMIN") {
            // must limit 5 min data requests
            if (startDate != null && endDate != null) {
                const startDateDate = new Date(startDate);
                const endDateDate = new Date(endDate);
                const diffTime = endDateDate.getTime() - startDateDate.getTime();
                // account for leap year
                const fiveYearsInMilliseconds = 157680000000 + 86400000;
                if (diffTime > fiveYearsInMilliseconds) {
                    showErrPrice = true;
                    setErrMessage("Max 5 min range is 5 years");
                }
            }
        } else {
            if (fixedSchedule && startDate != null && endDate != null) {
                const startDateDate = new Date(startDate);
                const endDateDate = new Date(endDate);
                const diffTime = endDateDate.getTime() - startDateDate.getTime();
                const tenYearsInMilliseconds = 315360000000
                if (diffTime > tenYearsInMilliseconds) {
                    showErrSim = true;
                    setErrMessage("Max simulate range is 10 years");
                }
            }
        }
        

        setShowError(showErrSim || showErrPrice);
        dateRangeErr(showErrPrice);
        dateRangeErrSim(showErrSim);
    }

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, priceMintimestamp, priceMaxtimestamp, granularity, fixedSchedule])

    return (
        <>
            { showError && <span className="error">{errorMessage}</span>}
        </>
    )
}
