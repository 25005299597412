import { RevenuesSummaryTable } from "./revenuesSummaryTable";
import { RevenuesTableTabs } from "./revenuesTableTabs";

export const PerfectForesightTable = () => {


    return (
        <>
            <RevenuesTableTabs />
            <RevenuesSummaryTable />
        </>
    )
}