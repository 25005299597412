import { createSlice } from "@reduxjs/toolkit"
import { WebSocketState } from "../states/websocket-state"
import config from 'config';

const initialState: WebSocketState = {
    socketPrice: false,
    socketFixedSchedule: false
}

export const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        wsConnected(state, action) {
            if (action.payload.includes(config.priceWs)) {
                state.socketPrice = true;
            } else {
                state.socketFixedSchedule = true;
            }
        },
        wsDisconnect(state, action) {
            if (action.payload.includes(config.priceWs)) {
                state.socketPrice = false;
            } else {
                state.socketFixedSchedule = false;
            }
        },
        wsDisconnected(state, action) {
            if (action.payload.includes(config.priceWs)) {
                state.socketPrice = false;
            } else {
                state.socketFixedSchedule = false;
            }
        }
    }
})

export const { wsConnected, wsDisconnect, wsDisconnected } = websocketSlice.actions;

export default websocketSlice.reducer;