import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import AYPAInput from "common/components/input";
import { DivContainer50 } from "common/components/divContainer";
import { setRoundTripEfficiency } from "pages/home/stores/slices/settings-slice";
import { ApplicationState } from "store";

interface Props {
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const RoundTripEfficiency = ({ isLoadingEnabled, isEntitySelected }: Props) => {

    /**Selectors */
    const [hasError, setHasError] = useState(false);
    const rte = useSelector((store: ApplicationState) => store.settingsReducer.roundTripEfficiency);

    /**Constants */
    const dispatch = useDispatch();
    const defaultValue = 88;
    const title = "Round Trip Efficiency";
    const percentSign = "%";
    const config = { type: 'number', id: 'rteInput', placeholder: '88', min: '0', max: '100', step: '0.1' };
    const errorMessage = "Value must be between 0.1 and 100";
    const initValue = rte ? rte : defaultValue;

    const handlerOnChangeRte = (data: any) => {
        const num_data = Number(data);
        if (0 >= num_data || num_data > 100.0) {
            setHasError(true)
            dispatch(setRoundTripEfficiency(null))
        } else {
            setHasError(false)
            dispatch(setRoundTripEfficiency(num_data));
        }
    }
    const onKeyPressHandler = () => { }

    return (
        <DivContainer50>
            <>
            <AYPAInput
                config={config}
                label={title}
                unit={percentSign}
                disabled={!isEntitySelected || isLoadingEnabled}
                onChangeCallback={handlerOnChangeRte}
                onKeyPressCallback={onKeyPressHandler}
                hasError={hasError}
                errorMessage={errorMessage}
                initalValue={initValue}
            />
            </>
        </DivContainer50>
    )
}

export default RoundTripEfficiency;
