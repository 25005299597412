import config from "config";
import {api} from "./api";

export const getPvConfiguration = (parameter: string) => {
    
    let params = new URLSearchParams();
    params.append("pv_system_id", parameter);

    api.defaults.baseURL = config.pvServiceBaseURL;
    return api.get(`pv-configs/`, { params });
}
