import { ApplicationState } from "store";
import { useEffect, useState } from "react"
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { setIso, setTimezone } from "pages/home/stores/slices/settings-slice";
import { getAllIso } from "common/adapters/entities";
import toast from 'react-hot-toast';


interface Props {
    isLoadingEnabled: boolean
}
export const Iso = ({ isLoadingEnabled }: Props) => {
    /**Constants */
    const dispatch = useDispatch();

    /**Selectors */
    const isoId = useSelector((store: ApplicationState) => store.settingsReducer.isoId);
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const nodeIdSelected = useSelector((store: ApplicationState) => store.settingsReducer.nodeId);

    /**State */
    const [iso_Selected, setIso_selected] = useState<Object | null>(null);
    const [listIso, setListIso] = useState<Array<any>>([]);
    const [isListLoading, setListLoading] = useState<boolean>(true);



    /** handlerOnChange */
    const handlerOnChangeIso = (selectedOption: any) => {
        const valueSelected = selectedOption ? { isoId: selectedOption.value, isoName: selectedOption.label, timezone: selectedOption.timezone } : { isoId: null, isoName: null, timezone: null }
        const isoSelected = selectedOption ? { value: selectedOption.value, label: selectedOption.label, timezone: selectedOption.timezone } : null
        setIso_selected(isoSelected);
        dispatch(setIso(valueSelected));
    }

    const loadIsoList = async () => {

        try {
            const response = await getAllIso(sourceId);
            const options = response.data.items.map((s: any) => {
                return { value: s.id, label: s.name, timezone: s.timezone }
            })
            setListIso(options)
        } catch {
            setListIso([])
            toast.error("Failed to load the ISO list. Please try again.");
        } finally {
            setListLoading(false)
        }

    }

    useEffect(() => {
        if (nodeIdSelected && listIso.length !== 0) {
            const isoLabel = listIso.find(obj => obj.value === isoId).label
            const timezone = listIso.find(obj => obj.value === isoId).timezone
            dispatch(setTimezone(timezone))
            setIso_selected({ value: isoId, label: isoLabel });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeIdSelected]);

    useEffect(() => {
        loadIsoList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Select
                id='isoDropdown'
                isClearable
                onChange={handlerOnChangeIso}
                options={listIso}
                value={iso_Selected ? iso_Selected : null}
                className='react-select-container'
                classNamePrefix="react-select"
                menuPlacement='auto'
                isDisabled={isLoadingEnabled}
                isLoading={isListLoading}
            />
        </>
    )
}
