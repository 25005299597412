import { useDispatch, useSelector } from 'react-redux';
import PV8760 from "./pv8760";
import { Battery } from "./battery";
import { IsoNode } from "./isoNode";
import { PriceMetadata } from "./priceMetadata";
import { DateRange } from "./dateRange";
import SimulationType from "./simulationType";
import { SettingsButtons } from "./actionButtons";
import { ApplicationState } from "store";
import { useEffect, useState } from 'react';
import { SourceSelector } from './sourceSelector';
import { Zonal } from './zonal';
import { Nodal } from './nodal';
import { WoodMac } from './woodMac';
import { SourceTypes, isEntitySelectedForSource } from '../stores/types/source-types';
import { priceInitialised } from '../stores/slices/prices-slice'
import { RoundTripEfficiency } from './efficiency/roundTripEfficiency';
import { CycleCount } from './cycleCount';
import { resetSimulation } from '../stores/slices/chart-slice';
import { PriceList } from './priceList';
import { AddToPriceListButton } from './buttonAddToPriceList';
import { DivContainer100 } from 'common/components/divContainer';
import { styleFlex } from 'common/components/style';

export const SettingsSection = () => {
    const dispatch = useDispatch();
    const isPriceLoading = useSelector((store: ApplicationState) => store.pricesReducer.isLoadingChart);
    const isSimulateLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const settings = useSelector((store: ApplicationState) => store.settingsReducer);
    const isLoading = isPriceLoading || isSimulateLoading;

    const [errorGetPrice, setErrorGetPrice] = useState<boolean>(false);
    const [errorSim, setErrorSim] = useState<boolean>(false);
    const [entitySelected, setEntitySelected] = useState<boolean>(false);
    const [priceListSelected, setPriceListSelected] = useState<boolean>(false);
    const [priceListTooLarge, setPriceListTooLarge] = useState<boolean>(false);

    const handleErrDateRange = (errValue: boolean) => {
        // bad date range, disable get prices button
        setErrorGetPrice(errValue);
    }
    const handleErrDateRangeSim = (errValue: boolean) => {
        // bad date range, disable simulate button
        setErrorSim(errValue);
    }

    useEffect(() => {
        // Reset Price Reducer and Simulate Reducer if any selection changes
        // dispatch(setPriceInit());
        dispatch(priceInitialised());
        dispatch(resetSimulation());
        setEntitySelected(isEntitySelectedForSource(settings.sourceId, settings.priceId, settings.nodeId, settings.locationId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.sourceId, settings.priceId, settings.locationId, settings.nodeId,
    settings.forecastId, settings.dateRange, settings.granularity, settings.priceList]);

    useEffect(() => {
        setPriceListSelected(settings.priceList.length > 0);
        setPriceListTooLarge(settings.priceList.length > 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.priceList]);

    return (
        <>
            <div className="scrollable scrollable-y">
                <SourceSelector isLoadingEnabled={isLoading} />
                {settings.sourceId === SourceTypes.NRG_STREAM &&
                    (<>
                        <IsoNode isLoadingEnabled={isLoading} />
                        <PriceMetadata isLoadingEnabled={isLoading} />
                    </>)
                }
                {settings.sourceId === SourceTypes.AYPA_ZONAL &&
                    (<>
                        <Zonal isLoadingEnabled={isLoading} showLocation={true} />
                    </>)
                }
                {settings.sourceId === SourceTypes.AYPA_NODAL &&
                    (<>
                        <Nodal isLoadingEnabled={isLoading} showLocationAndNode={true} />
                    </>)
                }
                {settings.sourceId === SourceTypes.WOOD_MAC &&
                    (<>
                        <WoodMac isLoadingEnabled={isLoading} showLocation={true} />
                    </>)
                }
                <DateRange isLoadingEnabled={isLoading} errorDateRange={handleErrDateRange} errorDateRangeSim={handleErrDateRangeSim} isEntitySelected={entitySelected} />
                <AddToPriceListButton disableGetPrice={errorGetPrice} isEntitySelected={entitySelected}></AddToPriceListButton>
                <PriceList simulateError={priceListTooLarge && (settings.fixedSchedule || settings.perfectForesight)}></PriceList>
                <SimulationType isLoadingEnabled={isLoading} isEntitySelected={entitySelected} />
                {(settings.fixedSchedule || settings.perfectForesight) && <Battery isLoadingEnabled={isLoading} isEntitySelected={entitySelected} />}
                <DivContainer100 style={styleFlex} ><>
                    {(settings.fixedSchedule || settings.perfectForesight) && <RoundTripEfficiency isLoadingEnabled={isLoading} isEntitySelected={entitySelected} />}
                    {settings.perfectForesight && <CycleCount isLoadingEnabled={isLoading} isEntitySelected={entitySelected} />}
                    </>
                </DivContainer100>
                {settings.fixedSchedule && <PV8760 isLoadingEnabled={isLoading} isEntitySelected={entitySelected} />}
                

            </div>
            <SettingsButtons disableGetPrice={errorGetPrice} disableSim={errorSim} isEntitySelected={priceListSelected && !priceListTooLarge} isPriceListSelected={priceListSelected}/>
        </>
    )
}
