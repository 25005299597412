import {api} from './api';
import config from 'config';

export const getHybridRevenue = (parameters: any) => {

    let params = new URLSearchParams();
    params.append("id", parameters.id);
    params.append("rated_power", parameters.batteryPower);
    
    parameters.group.forEach((element: string) => {
        params.append("group_by", element);
    });

    api.defaults.baseURL = config.revenuesBaseUrl;
    return api.get(`sum-hybrid-revenues/`, { params });
}

export const getHybridRevenueChart = (parameters: any) => {

    let params = new URLSearchParams();
    params.append("id", parameters.id);
    params.append("rated_power", parameters.batteryPower);
    parameters.group.forEach((element: string) => {
        params.append("group_by", element);
    });

    api.defaults.baseURL = config.revenuesBaseUrl;
    return api.get(`mean-hybrid-revenues/`, { params });
}

export const getHybridRevenueCsv = (id: string) => {
    
    let params = new URLSearchParams();
    params.append("format", 'csv');
    api.defaults.baseURL = config.revenuesBaseUrl;
    return api.get(`hybrid-revenues/${id}`, { params });
}
