import { useEffect, useState } from "react"
import config from '../../config';
import { AuthProps } from "common/interfaces/authProps";


export const useAuth = (authProps: AuthProps) => {

    const [isValid, setIsValid] = useState(false);

    async function getToken() {
        const token = await authProps.getAypaToken();
        window.sessionStorage.setItem(config.keyApiSession,token);      
        setIsValid(token !== undefined);
    }

    useEffect(() => {
        getToken();
    });

    return { isValid, getToken };
}
