import { createSlice } from "@reduxjs/toolkit";
import { TableState } from "../states/table-state";

const initialState: TableState = {
    isLoading: false,
    loadingMessage: 'Loading..',
    errorMessage: null
}

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setTablesLoading(state, action) {
            state.isLoading = action.payload;
            state.errorMessage = null
        },
        requestCompleted(state) {
            state.isLoading = false;
            state.errorMessage = null
        },
        requestFailed(state, action) {
            state.isLoading = false;
            state.errorMessage = action.payload
        },
        requestTimedOut(state, action) {
            state.isLoading = false;
            state.errorMessage = action.payload
        },
        requestCanceled(state, action) {
            state.isLoading = false;
            state.errorMessage = action.payload
        }
    }
})

export const { setTablesLoading, requestCanceled, requestCompleted, requestFailed, requestTimedOut } = tableSlice.actions;

export default tableSlice.reducer;