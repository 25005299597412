import classes from "./login.module.scss";
import sprites from "./../../assets/img/sprites.svg"

import microsoftLogin from "./../../assets/img/ms-symbollockup_signin_light.svg";

const Login = (props:any) => {
    const year = (new Date()).getFullYear();

    return (
        <div className={classes.login}>
            <div className={classes.login__logo}>
                        <svg>
                            <use xlinkHref={`${sprites}#logo`}></use>
                        </svg>
            </div>
            <div className={classes.login__cta}>
                <img src={microsoftLogin} onClick={props.login} alt="login" />
            </div>
            <div className={classes.login__legal}>
            &copy; {year} Aypa Power. All rights reserved
            </div>
        </div>
    )
}

export default Login;
