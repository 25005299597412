import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import sprites from "common/assets/img/sprites.svg";
import SpinnerButton from "../sprinnerButton";
import classes from "./../../style.module.scss";
import { DataToFileDownload} from "common/utils/utils";
import { requestFailed } from "pages/home/stores/slices/prices-slice";
import { setCSVDownloading, successOperationPrice } from "pages/home/stores/slices/prices-slice";
import { wsConnect, wsSendMessage } from "pages/home/stores/actions/websocket-action";
import config from 'config';
import { setGetPriceButton } from "pages/home/stores/slices/settings-slice";
import { SourceTypes } from "pages/home/stores/types/source-types";
import toast from 'react-hot-toast';

export const ButtonDownloadNodesCsv = () => {
    /**SELECTORS */
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const pricesReducer = useSelector((store: ApplicationState) => store.pricesReducer);
    const socketPrice = useSelector((store: ApplicationState) => store.websocketReducer.socketPrice);

    /**CONSTANTS */
    const dispatch = useDispatch();
    let titleButton = 'Download Nodes for Region';
    const downloadTypeNodes = "extractNodes";

    const downloadAsCSV = (event: any) => {
        dispatch(setCSVDownloading({isCSVDownloading: true, downloadType: downloadTypeNodes}));
    }
    const timeoutTime = 180000; //3min update error message to match

    const [timeOutId, setTimeOutId] = useState<any>(null);

    useEffect(() => {
        if (pricesReducer.isCSVDownloading && pricesReducer.downloadType === downloadTypeNodes && !socketPrice) {
            dispatch(wsConnect(config.priceWs));
        }
        if (pricesReducer.isCSVDownloading && pricesReducer.downloadType === downloadTypeNodes && socketPrice && settingsReducer.priceList.length > 0) {
            const nodes: Array<any> = [];
            // backend will prevent duplicates
            const regionSourceSet = new Set<string>();
            settingsReducer.priceList.forEach(price => {
                let regionSource = "";
                if (price.sourceId === SourceTypes.NRG_STREAM) {
                    regionSource = price.isoId ? price.isoId.toString() + "_" + price.sourceId.toString() : price.sourceId.toString();
                    if (!regionSourceSet.has(regionSource)) {
                        regionSourceSet.add(regionSource);
                        nodes.push({
                            region_id: price.isoId,
                            source_id: price.sourceId
                        });
                    }
                } else if (price.sourceId === SourceTypes.AYPA_NODAL) {
                    regionSource = price.regionId ? price.regionId.toString() + "_" + price.sourceId.toString() : price.sourceId.toString();
                    if (!regionSourceSet.has(regionSource)) {
                        regionSourceSet.add(regionSource);
                        nodes.push({
                            region_id: price.regionId,
                            source_id: price.sourceId
                        });
                    }
                }
            })
            if (nodes.length > 2) {
                dispatch(setCSVDownloading({isCSVDownloading: false, downloadType: ""}));
                toast.error("Max of 2 different regions can be selected.");
                return;
            }
            let parameter: any = {
                action: "extract-nodes",
                node_requests: nodes,
                event_id: "AAA",
            }
            const jsonString: string = JSON.stringify(parameter);
            dispatch(wsSendMessage(jsonString, config.priceWs));

            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }
            const timeOut = setTimeout(() => {
                dispatch(requestFailed({ "message": "Request timed out.", "details": "Request took longer than 3mins." }));
                dispatch(setGetPriceButton(false));
            }, timeoutTime);
            setTimeOutId(timeOut)

        } else {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricesReducer.isCSVDownloading, socketPrice, pricesReducer.downloadType]);

    useEffect(() => {
        if (pricesReducer.completedDownloadCSV && pricesReducer.downloadType === downloadTypeNodes) {
            const loadedData = pricesReducer.loadedData;
            let priceJson = null;
            try {
                // only errors are in json
                priceJson = JSON.parse(loadedData)
            } catch (e) {
                priceJson = null;
            }
            if (priceJson) {
                responseDoneWithError(priceJson);
            } else {
                responseWithoutError(loadedData);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricesReducer.completedDownloadCSV, pricesReducer.downloadType]);

    const responseDoneWithError = (data: any) => {
        console.error("Error on download: ", data.error)
        dispatch(requestFailed({ message: data.error }));
        dispatch(setGetPriceButton(false));
    }

    const responseWithoutError = (data: any) => {
        // convert data to csv and save
        let filename = "node_extract";
        DataToFileDownload(data, filename);
        dispatch(successOperationPrice(data));
    }

    return (
        <div className={classes.card__dropdownitem}>
            <button onClick={downloadAsCSV}
                disabled={pricesReducer.executeRunPriceLookup || pricesReducer.isCSVDownloading}>
                {pricesReducer.isCSVDownloading && pricesReducer.downloadType === downloadTypeNodes && <SpinnerButton />}
                {!pricesReducer.isCSVDownloading && <span>
                    <svg>
                        <use xlinkHref={`${sprites}#icon-export`}></use>
                    </svg>
                </span>}
                {titleButton}
            </button>
        </div>
    )
}
