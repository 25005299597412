import { ApplicationState } from "store";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SimulateChartTypes } from "pages/home/stores/types/simulate-chart-types";
import { executeHybridRevenueCompleteLoaded } from "pages/home/stores/slices/chart-slice";

interface HybridRevenuesTableTableProps {
    group: Array<string>
}

export const useHybridRevenuesTable = ({ group }: HybridRevenuesTableTableProps) => {

    /**Constants */
    const dispatch = useDispatch();

    /**States*/
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hybridRevenues, setHybridRevenues] = useState<Array<any>>([]);
    const [errorMessage, setErrorMessage] = useState();

    /**Selectors*/
    const hybridRevenueId = useSelector((store: ApplicationState) => store.chartReducer.hybridRevenueId);
    const executeHybridRevenue = useSelector((store: ApplicationState) => store.chartReducer.executeHybridRevenue);
    const executeHybridRevenueComplete = useSelector((store: ApplicationState) => store.chartReducer.executeHybridRevenueComplete);
    const executeHybridRevenueData = useSelector((store: ApplicationState) => store.chartReducer.executeHybridRevenueData);
    const executeHybridRevenueError = useSelector((store: ApplicationState) => store.chartReducer.executeHybridRevenueError);
    const batteryPower = useSelector((store: ApplicationState) => store.settingsReducer.batteryPower);

    const onCompleteFetchHybridRevenues = () => {
        if (executeHybridRevenueError) {
            setErrorMessage(executeHybridRevenueError)
        } else {
            setHybridRevenues(executeHybridRevenueData);
        }
        setIsLoading(false);
        dispatch(executeHybridRevenueCompleteLoaded());
    }

    const fetchHybridRevenues = () => {
        setIsLoading(true);
        const parameters = { id: hybridRevenueId, batteryPower: batteryPower, group };
        dispatch({ type: SimulateChartTypes.FETCH_HYBRID_REVENUES, parameters });
    }

    useEffect(() => {
        // this is always true once data has been loaded.  New click tab allows this to trigger again
        if (executeHybridRevenue) {
            fetchHybridRevenues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeHybridRevenue]);

    useEffect(() => {
        if (executeHybridRevenueComplete) {
            onCompleteFetchHybridRevenues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeHybridRevenueComplete]);

    return { isLoading, errorMessage, hybridRevenues };
}
