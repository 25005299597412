/* Line Chart Render values
 * labels, months, quarters, colors4, colors12, colors40
 */
export const hour_labels = [
  '0','1','2','3','4','5','6','7','8','9','10','11','12',
  '13','14','15','16','17','18','19','20','21','22','23'
];

const labels = [];
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 5) {
    const hourLabel = hour < 10 ? `0${hour}` : `${hour}`;
    const minuteLabel = minute < 10 ? `0${minute}` : `${minute}`;
    labels.push(`${hourLabel}:${minuteLabel}`);
  }
}
export const five_min_labels = labels

export const months = [
  'JAN', 'FEB', 'MAR', 'APR',  'MAY', 'JUN',
  'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
];
export const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
export const wint_sum = ['Winter', 'Summer'];
export const colors4 = ['#000004', '#781D6D', '#EE6A24', '#F2F481'];
export const colors12 =[
  '#000004', '#0E082B', '#2D0B5A', '#500D6D', '#781D6D', '#AD315D',
  '#D34743', '#EE6A24', '#F98C0B', '#FDB014', '#F6D745', '#F2F481'
];
//  Old color pallete
// export const colors40 = [
//   '#000004', '#02020D', '#08051C', '#0E082B', '#180C3C', '#210C4A',
//   '#2D0B5A', '#380963', '#450A69', '#500D6D', '#590F6F', '#64146E',
//   '#6E186E', '#781D6D', '#83206C', '#8D236A', '#992766', '#A42C61',
//   '#AD315D', '#B73557', '#C03A51', '#CA404A', '#D34743', '#DA4F3D',
//   '#E25734', '#E9612C', '#EE6A24', '#F3761B', '#F78112', '#F98C0B',
//   '#FB9806', '#FCA40A', '#FDB014', '#FCBE23', '#F9CA33', '#F6D745',
//   '#4182FB', '#4199FB', '#1D3E98', '#060270'
// ];
//  colors are more distinct between each other - easier to read graphs
export const colors40 = [
    '#e15d29', '#013220', '#29ade1', '#e1b929', '#592720', '#2a52be',
    '#29e1b9', '#5d29e1', '#29e15d', '#e129ad', '#e12f29', '#ea906c',
    '#0a0401', '#d8531e', '#e15d29', '#8d827d', '#fd510d', '#177245',
    '#483c32', '#cc4e5c', '#9400d3', '#1560bd', '#85bb65', '#e03c31',
    '#E25734', '#E9612C', '#EE6A24', '#F3761B', '#F78112', '#F98C0B',
    '#FB9806', '#FCA40A', '#FDB014', '#FCBE23', '#F9CA33', '#F6D745',
    '#4182FB', '#4199FB', '#1D3E98', '#060270'
  ];


export const lineTension = 0;
export const borderWidth = 1.5;
export const pointHoverRadius = 8;
export const pointRadius = 1;
