import { RevenuesTableTabs } from "./revenuesTableTabs";
import { SummaryTable } from "./summaryTable";

export const FixedScheduleTable = () => {


    return (
        <>
            <RevenuesTableTabs />
            <SummaryTable />
        </>
    )
}