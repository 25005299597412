interface Config {
  // Services
  priceServiceBaseURL: string;
  fixedScheduleServiceBaseURL: string;
  operationsServiceBaseURL: string;
  pvServiceBaseURL: string;
  revenuesBaseUrl:string;

  // Auth
  authAuthority: string;
  appId: string;
  redirectUri: string;
  graphScopes: string[];
  aypaScopes: string[];

  // LocalDev
  token?: string;
  keyApiSession: string;
  baseUrl: string;
  priceWs: string;
  fixedScheduleWs: string;
}

// Useful for Debugging ENV variables set at build time
// console.log("ENV variable 'REACT_APP_TARGET_ENV': " + process.env.REACT_APP_TARGET_ENV);

const isProd = process.env.REACT_APP_TARGET_ENV === 'prod';
const isStaging = process.env.REACT_APP_TARGET_ENV === 'staging';

// NODE_ENV is set to "production" on "npm build"-- Need to separate a dev env build from a prod env build
const redirectUri = isProd ? 'https://analytics.aypa.com' : (isStaging ? 'https://analytics-staging.aypa.com' : 'https://analytics-dev.aypa.com');

var config: Config = {
  // Services
  priceServiceBaseURL: isProd ? 'https://price-service.api-us.aypa.com' : (isStaging ? 'https://price-service.api-us-staging.aypa.com' : 'https://price-service.api-us-dev.aypa.com'),
  fixedScheduleServiceBaseURL: isProd ? 'https://fixed-schedule-service.api-us.aypa.com/v1/' : (isStaging ? 'https://fixed-schedule-service.api-us-staging.aypa.com/v1/' : 'https://fixed-schedule-service.api-us-dev.aypa.com/v1/'),

  pvServiceBaseURL: isProd ? 'https://pv-service.api-us.aypa.com/v1/' : (isStaging ? 'https://pv-service.api-us-staging.aypa.com/v1/' : 'https://pv-service.api-us-dev.aypa.com/v1/'),
  revenuesBaseUrl: isProd ? 'https://revenue-service.api-us.aypa.com/v1/' : (isStaging ? 'https://revenue-service.api-us-staging.aypa.com/v1/' : 'https://revenue-service.api-us-dev.aypa.com/v1/'),
  operationsServiceBaseURL: isProd ? 'https://operations-service.api-us.aypa.com/v1/' : (isStaging ? 'https://operations-service.api-us-staging.aypa.com/v1/' : 'https://operations-service.api-us-dev.aypa.com/v1/'),

  // Auth
  authAuthority: 'https://login.microsoftonline.com/9388eab7-2d20-4a34-bf17-16f33594fd49',
  appId: isProd ? 'ffde4806-5d27-474d-b5ff-2d5314c9bcc7' : (isStaging ? 'cd41521e-fd15-4950-a271-d82bbcb64012' : '9b7c20f7-12c0-4c05-91a0-4a5279bbfe41'),
  redirectUri: process.env.NODE_ENV === "production" ? redirectUri : 'http://localhost:3000',
  graphScopes: [
    'user.read',
    'mailboxsettings.read',
    'calendars.readwrite',
  ],
  aypaScopes: [
    isProd ? 'api://api-us.aypa.com/all' : (isStaging ? 'api://api-us-staging.aypa.com/all' : 'api://api-us-dev.aypa.com/all')
  ],
  keyApiSession: 'aypa-token',
  baseUrl: isProd ? 'https://price-service.api-us.aypa.com/v1/' : (isStaging ? 'https://price-service.api-us-staging.aypa.com/v1/' : 'https://price-service.api-us-dev.aypa.com/v1/'),
  priceWs: isProd ? 'wss://ws-price-service.api-us.aypa.com/' : (isStaging ? 'wss://ws-price-service.api-us-staging.aypa.com/' : 'wss://ws-price-service.api-us-dev.aypa.com/'),
  fixedScheduleWs: isProd ? 'wss://ws-fixed-schedule-service.api-us.aypa.com/' : (isStaging ? 'wss://ws-fixed-schedule-service.api-us-staging.aypa.com/' : 'wss://ws-fixed-schedule-service.api-us-dev.aypa.com/')
};

export default config;
