import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {Chart,  registerables as ChartRegisterables } from 'chart.js';
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import { LineData } from "common/interfaces/lineTypes";
import { months, wint_sum } from './values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

Chart.register(...ChartRegisterables, zoomPlugin);

interface Props {
  data?: Array<LineData>,
  config?: any,
  mode?: any,
  onCompleteCallback: Function
  labels?: any
}

const LineChart = (props: Props) => {
  const config = props.config;
  config.animation = {
    duration: 280,
    onComplete: props.onCompleteCallback()
  };
  let showZoomButtons = false;
  if (config.plugins && config.plugins.zoom) {
    showZoomButtons = true;
  }



const colors = ["#e15d29","#1E90FF","#9ACD32","#20B2AA","#6A5ACD","#FFD700" ,"#DB7093","#9400D3", "#FF8C00","#32CD32","#4682B4","#C71585"];
  if (props.data && props.data.length > 0){
    props.data.forEach(graph => {
        // looping over each graph
        for (let i = 0; i < graph.dataSets.length; i++) {
            graph.dataSets[i].borderColor = colors[i % colors.length];
            graph.dataSets[i].backgroundColor = colors[i % colors.length];
        }

    })
  }


  

  const lineChartRef: any = React.useRef(null);

  const handleZoom = (type: string) => {
    if (lineChartRef && lineChartRef.current) {
        switch (type) {
            case "reset":
                lineChartRef.current.resetZoom();
                break;
            case "in":
                lineChartRef.current.zoom(1.1);
                break;
            case "out":
                lineChartRef.current.zoom(0.9);
                break;
            default:
                lineChartRef.current.resetZoom();
        }        
    }
  };

  return (
    <>
      {props.data?.map((value, index) => {
        // checks if data only has 1 point, display as bar chart instead
        if (value.dataSets[0].data.length === 1 || props.mode === 'barGraph') {
            return (
                <div key={index} className='chartjs'>
                  {((props.mode === 'month' || props.mode === 'month_pf') && value.multiIndex) && <span>{months[value.multiIndex-1]}</span>}
                  {((props.mode === 'wint_sum' || props.mode === 'wint_sum_pf') && (value.multiIndex !== null && value.multiIndex !== undefined)) && <span>{wint_sum[value.multiIndex]}</span>}
                  <Bar
                    data={{labels: props.labels, datasets: value.dataSets}}
                    options={props.config}
                  />
                </div>
            )
        } else {
            return (
                <div key={index} className='chartjs'>
                    {((props.mode === 'month' || props.mode === 'month_pf') && value.multiIndex) && <span>{months[value.multiIndex-1]}</span>}
                    {((props.mode === 'wint_sum' || props.mode === 'wint_sum_pf') && (value.multiIndex !== null && value.multiIndex !== undefined)) && <span>{wint_sum[value.multiIndex]}</span>}
                    <Line
                    ref={lineChartRef}
                    data={{labels: props.labels, datasets: value.dataSets}}
                    options={props.config}
                    />
                    {showZoomButtons && (
                    <div className="flex-container-center flex-gap-5">
                        <button onClick={() => handleZoom("in")}><FontAwesomeIcon icon={faMagnifyingGlassPlus} /></button>
                        <button onClick={() => handleZoom("out")}><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></button>
                        <button onClick={() => handleZoom("reset")}>Reset Zoom</button>
                    </div>
                    )}
                    
                </div>
            )
        }
      })}
    </>
  );
}
export default LineChart;
