import { useEffect } from "react"
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { wsConnect, wsSendMessage } from "pages/home/stores/actions/websocket-action";
import config from 'config';
import { PriceTypes } from "pages/home/stores/types/price-types";
import { SourceTypes } from "pages/home/stores/types/source-types";
import { runPerfectForesight } from "pages/home/stores/slices/chart-slice";
import { addDayToDateStr, formatDate } from "common/utils/utils";
/**
 * useInitRunPerfectForesight Hook
 * 
 * A React hook for initiating and managing the process of running pefect foresight based on websocket connection conditions.
 *
 * @returns {void}
 */

export const useInitRunPerfectForesight = (checkAypaToken: Function) => {

    /**Constant */
    const dispatch = useDispatch();

    /**Selectors */
    const executeRunPerfectForesight = useSelector((store: ApplicationState) => store.chartReducer.executeRunPerfectForesight);
    const executePrepPerfectForesight = useSelector((store: ApplicationState) => store.chartReducer.executePrepPerfectForesight);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const socketFixedSchedule = useSelector((store: ApplicationState) => store.websocketReducer.socketFixedSchedule);
      
    useEffect(() => {
        checkAypaToken();
        if (executeRunPerfectForesight && !socketFixedSchedule) {
            dispatch(wsConnect(config.fixedScheduleWs));
        }
        if (executeRunPerfectForesight && socketFixedSchedule && settingsReducer.priceList.length > 0) {
            // price is entity related, all else is settingsReducer.  forecastKey is not stored in priceList
            const price = settingsReducer.priceList[0];
            const startTimestamp = price.dateRange ? price.dateRange[0] : price.priceMintimestamp;
            const endTimestamp = price.dateRange ? price.dateRange[1] : price.priceMaxtimestamp;
            const endDateStr = addDayToDateStr(endTimestamp);
            // rre is number, backend needs percentage
            const rre = settingsReducer.roundTripEfficiency ? settingsReducer.roundTripEfficiency : 88;
            // node name is nodeBus or nodeName
            const node_name = price.nodeBus ? price.nodeBus : price.nodeName;
            const parameter = {
                action: "perfect-foresight",
                rated_power: settingsReducer.batteryPower,
                duration: settingsReducer.batteryHour,
                roundtrip_efficiency: rre/100,
                cycles_per_day: settingsReducer.cycleCount,
                start_date: formatDate(startTimestamp),
                end_date: formatDate(endDateStr),
                granularity: price.granularity,
                stream_id: price.streamId,
                forecast_location: settingsReducer.forecastKey,
                node_name: node_name
            };
            const jsonString: string = JSON.stringify(parameter);
            dispatch(wsSendMessage(jsonString, config.fixedScheduleWs));
            
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunPerfectForesight, socketFixedSchedule]);

    useEffect(() => {
        if (executePrepPerfectForesight) {
            const price = settingsReducer.priceList[0];
            // dispatching to lookup key if needed.  will set executeRunPerfectForesight to true if successful
            if (price.sourceId !== SourceTypes.NRG_STREAM && settingsReducer.forecastKey === null) {
                const parameter = {
                    "id": price.forecastId,
                    "location_id": price.locationId,
                    "node_id": price.nodeId
                }
                dispatch({ type: PriceTypes.FETCH_FORECAST_KEY, parameter });
            } else {
                // NRG data already has data loaded.  sets executeRunPerfectForesight to true
                dispatch(runPerfectForesight())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executePrepPerfectForesight])
}