import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ButtonDownloadAsPng } from './buttonDownloadAsPng';

export const ButtonDownloadPriceProfileAsPng = () => {
    /**SELECTORS */
    const chartBase64Image = useSelector((store: ApplicationState) => store.settingsReducer.priceChartBase64Image);
    return (
        <ButtonDownloadAsPng chartBase64Image={chartBase64Image} filename="PriceProfile" />
    )
}
