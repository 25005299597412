import { LineData } from "common/interfaces/lineTypes";
import _ from "lodash";
import { months, wint_sum, lineTension, borderWidth, pointHoverRadius, pointRadius } from './values';
import { ForesightRevenueData } from "common/interfaces/perfectForesightData";
import { PriceDataChart, PriceDataRawChart } from "common/interfaces/priceData";
import { DateTime } from "luxon";

/* getConfig
 * returns a Chart.js config object
 * yAxisLabel: label for y axis
 * xAxisLabel: label for x axis
 * displayLegend: display legend on title or not
 */
export const getConfig = (yAxisLabel: string, xAxisLabel: string, displayLegend: boolean, hoverLabelType: string = 'currency', dualYAxisLabel?: string) => {
 
  const config: any = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: displayLegend,
            onClick: reverseLegendClickHandler
          },
        tooltip: {
            callbacks: {
              title: function (tooltipItem: any) {
                return tooltipItem[0].dataset.label + ' ' + xAxisLabel + ' ' + tooltipItem[0].label;
              },
              label: function (tooltipItem: any) {
                let label;
                if (hoverLabelType === 'currency') {
                  label = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(tooltipItem.formattedValue);
                } else {
                  label = tooltipItem.formattedValue;
                }
                return label;
              }
            }
        }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel
        }
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel
        }
      }
    }
  }
  if (dualYAxisLabel) {
    config["scales"]["y2"] = {
        title: {
            display: true,
            text: dualYAxisLabel
          },
          id: 'y2',
          position: 'right'
    }
  }
  return config;
};

export const getConfigTimestamp = (yAxisLabel: string, xAxisLabel: string, displayLegend: boolean, hoverLabelType: string = 'currency') => {

    return {
      maintainAspectRatio: false,
      responsive: true,
      animation: false,
      parsing: false,
      plugins: {
        legend: {
            display: displayLegend,
            onClick: reverseLegendClickHandler
          },
        tooltip: {
            callbacks: {
              title: function (tooltipItem: any) {
                return tooltipItem[0].dataset.label + ' ' + xAxisLabel + ' ' + tooltipItem[0].label;
              },
              label: function (tooltipItem: any) {
                let label;
                if (hoverLabelType === 'currency') {
                  label = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(tooltipItem.raw["y"]);
                } else {
                  label = new Intl.NumberFormat('en-us').format(tooltipItem.tooltipItem.raw["y"]);
                }
                return label;
              }
            }
        },
        zoom: {
            pan: {
              enabled: true,
              mode: 'x',
              modifierKey: "ctrl"
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              drag: {
                enabled: true,
              },
              mode: 'x',
            //   TODO resample large data - test more
            //@ts-ignore
              onZoom: ({chart}) => {
                const { min, max } = chart.scales.x;
                const range = DateTime.fromMillis(max).diff(DateTime.fromMillis(min), 'days').days;
    
                if (range < 30) {
                  // Show hourly data
                //   chart.data.datasets[0].data = generateData(DateTime.fromMillis(min), DateTime.fromMillis(max), 'hours');
                  chart.options.scales.x.time.unit = 'hour';
                } else if (range < 365) {
                  // Show daily data
                //   chart.data.datasets[0].data = generateData(DateTime.fromMillis(min), DateTime.fromMillis(max), 'days');
                  chart.options.scales.x.time.unit = 'day';
                } else {
                  // Show monthly data
                //   chart.data.datasets[0].data = generateData(DateTime.fromMillis(min), DateTime.fromMillis(max), 'months');
                  chart.options.scales.x.time.unit = 'month';
                }
    
                chart.update('none');
                }
          },
        },
        decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 500,
            threshold: 500
        }
    },
      scales: {
        y: {
            title: {
                display: true,
                text: yAxisLabel
          }
        },
        x: {
            type: 'time',
            time: {
                unit: 'month',
                displayFormats: {
                    hour: 'D HH:mm'
                }
            }
        }
      }
    }
  };
  

/* formatPriceDataAll
 * Formats multiple Price profile service data and returns a rendeable chart LineData array
 * groupName: grouping property (month, wint_summ, year)
 * dataValue: value property (month, wint_summ, year)
 * extraGrouping: multicharts (month or wint_summ)
 */
export const formatPriceDataAll  = (elements: Array<PriceDataChart>, groupName: string, dataValue: string, extraGrouping: string | null): Array<LineData> => {
    let grouped: Array<any> = [];
  
    //normalize groups
    if (groupName) {
      if (extraGrouping) {
        for (const element of elements) {
            let grp = _.groupBy(element.data, extraGrouping);
            _.each(grp, (subgrp) => {
                grouped.push({"label": element.name, "data": _.groupBy(subgrp, groupName)})
            });
        }
      } else {
        for (const element of elements) {
            grouped.push({"label": element.name, "data": _.groupBy(element.data, groupName)});
        }
      }
    } else {
        for (const element of elements) {
            grouped.push({"label": element.name, "data": [element.data]});
        }
    }
  
    const lineData: Array<LineData> = [];
    //generate charts
    _.each(grouped, (subgroup, idx) => {
      let dataSets: Array<any> = [];
      let multiIndex = 0;
      //generate dataSets
      _.each(subgroup.data, (group, index) => {
        let data: Array<object> = [];                               
        _.each(group, (val, key) => {
          multiIndex = val.month ? val.month : val.wint_sum;
          if (val.min !== null && val.min !== undefined) {
            // 5min data, put hours and minutes
            data.push({ x: val.hour.toString().padStart(2, '0') + ":" + val.min.toString().padStart(2, '0'), y: val[dataValue] });
          } else {
            data.push({ x: val.hour.toString(), y: val[dataValue] });
          }
          
        });
        let label = (groupName === 'month') ? subgroup.label  + ": " + months[parseInt(index) - 1] : (groupName === 'wint_sum') ? subgroup.label + ": " + wint_sum[parseInt(index)] : (index.toString() !== '0') ? subgroup.label + ": "+ index : subgroup.label
  
        dataSets.push({
          label: label,
          fill: false,
          data: data,
          lineTension: lineTension,
          borderWidth: borderWidth,
          pointHoverRadius: pointHoverRadius,
          pointRadius: pointRadius
        });
      });
  
      lineData.push({
        multiIndex: multiIndex,
        dataSets: dataSets
      });
    });
    // group line data.
    const combinedLineData: Array<LineData> = [];
    lineData.forEach(obj => {
        let foundMatch = false;
        combinedLineData.forEach(newObj => {
            if (newObj.multiIndex === obj.multiIndex) {
                newObj.dataSets.push(...obj.dataSets);
                foundMatch = true;
            }
        });
        if (!foundMatch) {
            combinedLineData.push({
                multiIndex: obj.multiIndex,
                dataSets: obj.dataSets
            });
        }
    });
    return combinedLineData;
  }
  

/* formatPriceDataRawAll
 * Formats multiple Price profile service data and returns a rendeable chart LineData array
 */
export const formatPriceDataRawAll  = (priceDataRawChart: Array<PriceDataRawChart>) => {
    const lineData: Array<LineData> = [];
    let dataSets: Array<any> = [];
    const timestamps: Set<DateTime> = new Set<DateTime>();
    //generate charts
    _.each(priceDataRawChart, (dataset, idx) => {
        const raw_data: Array<object> = [];
        _.each(dataset.data, (point, index) => {
            raw_data.push({ x: point["timestamp"].toMillis(), y: point["price"] });
            timestamps.add(point["timestamp"]);
        })
        dataSets.push({
            label: dataset.name,
            fill: false,
            data: raw_data,
            lineTension: lineTension,
            borderWidth: borderWidth,
            pointHoverRadius: pointHoverRadius,
            pointRadius: pointRadius
            });
    });
    lineData.push({
        dataSets: dataSets
    });
    return {lineData, timestamps};
  }
  

/* formatSimulationData
 * Formats Simulations services data and returns a rendeable chart LineData array
 * accepts extraGrouping for multicharts (month or wint_sum)
 */
export const formatSimulationData = (elements: Array<any>, extraGrouping: string | null): Array<LineData> => {
  let grouped: any = [];

  //normalize groups
  if (extraGrouping) {
    grouped = _.groupBy(elements, extraGrouping);
  } else {
    grouped = [elements];
  }

  const lineData: Array<LineData> = [];
  //generate charts
  _.each(grouped, (subgroup, idx) => {
    let dataSets: Array<any> = [];
    let multiIndex = 0;
    //each subgroup is a chart
    let systems: Array<number> = [];
    let generation: Array<number> = [];
    //generate dataSets
    _.each(subgroup, (group, index) => {
      multiIndex = group['month'] ? group['month'] : (group['wint_sum'] !== null &&  group['wint_sum'] !== undefined) ? group['wint_sum'] : null;
      systems.push(group['system_grid']);
      generation.push(group['generation']);
    });

    dataSets.push({
      label: 'System',
      fill: false,
      data: systems,
      lineTension: lineTension,
      borderWidth: borderWidth,
      pointHoverRadius: pointHoverRadius,
      pointRadius: pointRadius
    });

    if (generation.length) {
      dataSets.push({
        label: 'PV',
        fill: false,
        data: generation,
        lineTension: lineTension,
        borderWidth: borderWidth,
        pointHoverRadius: pointHoverRadius,
        pointRadius: pointRadius
      });
    }

    lineData.push({
      multiIndex: multiIndex,
      dataSets: dataSets
    });
  });

  return lineData;
}

/* formatPerfectForesightMean
 * Formats perfect foresight s data and returns a rendeable chart LineData array
 * accepts extraGrouping for multicharts (month or wint_sum)
 */
export const formatPerfectForesightMean = (elements: Array<any>, groupName: string, extraGrouping: string | null): Array<LineData> => {
  let grouped: any = [];
  //normalize groups
  if (groupName) {
    if (extraGrouping) {
      let grp = _.groupBy(elements, extraGrouping);
      _.each(grp, (subgrp) => {
        grouped.push(_.groupBy(subgrp, groupName));
      });
    } else {
      grouped.push(_.groupBy(elements, groupName));
    }
  } else {
    // Overall graph, 2 grouped by only hour
    grouped.push([elements]);
  }



  const lineData: Array<LineData> = [];
  //generate charts
  _.each(grouped, (subgroup, idx) => {
    let dataSets: Array<any> = [];
    let multiIndex = 0;
    //each subgroup is a chart
    //generate dataSets

    _.each(subgroup, (group, index) => {
      // subgroup is arrary of value objects
      let data_buy: Array<number> = [];
      let data_sell: Array<number> = [];
      _.each(group, (val, key) => {
        multiIndex = val.month ? val.month : val.wint_sum;
        data_buy.push(val['buy']);
        data_sell.push(val['sell']);
      });
      let label = (groupName === 'month') ? months[parseInt(index) - 1] : (groupName === 'wint_sum') ? wint_sum[parseInt(index)] : parseInt(index) === 0 ? '' : index;

      dataSets.push({
        label: 'Buy ' + label,
        fill: false,
        data: data_buy,
        lineTension: lineTension,
        borderWidth: borderWidth,
        pointHoverRadius: pointHoverRadius,
        pointRadius: pointRadius
      });

      dataSets.push({
        label: 'Sell ' + label,
        fill: false,
        data: data_sell,
        lineTension: lineTension,
        borderWidth: borderWidth,
        pointHoverRadius: pointHoverRadius,
        pointRadius: pointRadius
      });
    });
    lineData.push({
      multiIndex: multiIndex,
      dataSets: dataSets
    });
  });
  return lineData;
}


export const formatPerfectForesightSum = (elements: Array<ForesightRevenueData>, extraGrouping: string) => {

  const lineData: Array<LineData> = [];
  const years_label: Set<number> = new Set<number>();

  let grouped: any = [];
  grouped = _.groupBy(elements, extraGrouping[1]);

  _.each(grouped, (subgroup, idx) => {
    let dataSets: Array<any> = [];
    let multiIndex = 0;
    let opt_rev: Array<object> = [];
    let cycle: Array<object> = [];
    let tb: Array<object> = [];

    _.each(subgroup, (group, index) => {
      years_label.add(group['year'])
      multiIndex = group['month'] ? group['month'] : (group['wint_sum'] !== null &&  group['wint_sum'] !== undefined) ? group['wint_sum'] : null;
      let xAxisName = null;
      if (group['year']) {
        xAxisName = group['year'];
      } else if (group['wint_sum'] !== null &&  group['wint_sum'] !== undefined) {
        xAxisName = wint_sum[group['wint_sum']];
      } else if (group['month']) {
        xAxisName = group['month']
      }
      opt_rev.push({ x: xAxisName, y: group['optimization_revenue'] });
      cycle.push({ x: xAxisName, y: group['cycle_count'] });
      tb.push({ x: xAxisName, y: group['top_bottom_revenue'] });
    });

    dataSets.push({
      label: 'PF Revenue',
      fill: false,
      data: opt_rev,
      lineTension: lineTension,
      borderWidth: borderWidth,
      pointHoverRadius: pointHoverRadius,
      pointRadius: pointRadius,
      yAxisID: 'y',
    })

    dataSets.push({
      label: 'TB Revenue',
      fill: false,
      data: tb,
      lineTension: lineTension,
      borderWidth: borderWidth,
      pointHoverRadius: pointHoverRadius,
      pointRadius: pointRadius,
      yAxisID: 'y'
    })

    dataSets.push({
      label: 'Cycle Count',
      fill: false,
      data: cycle,
      lineTension: lineTension,
      borderWidth: borderWidth,
      pointHoverRadius: pointHoverRadius,
      pointRadius: pointRadius,
      yAxisID: 'y2'
    })

    lineData.push({
      multiIndex: multiIndex,
      dataSets: dataSets
    })
  })

  return { lineData, years_label }
}

/**
 * This is triggered when a legend item is clicked.  instead of hidding the one clicked, it will hide all others
 * If the same legend is clicked again, it will reset 
 * @param event 
 * @param legendItem 
 * @param legend 
 */
const reverseLegendClickHandler = function (event: any, legendItem: any, legend: any) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    const indexNotHidden = legend.legendItems.findIndex((item: any) => !item.hidden);
    const anyHidden = legend.legendItems.some((item: any) => item.hidden);
    let reverseHidden = false;
    if (indexNotHidden === index && anyHidden) {
        reverseHidden = true;
    }
    for (let i=0; i<legend.legendItems.length; i++) {
        if (reverseHidden) {
            ci.show(i);
            legend.legendItems[i].hidden = false;
        } else {
            ci.hide(i);
            legend.legendItems[i].hidden = true;
        }
    }
    if (!reverseHidden) {
        ci.show(index);
        legendItem.hidden = false;
    }
};