import DefaultButton from 'common/components/button';
import classes from './style.module.scss';
import { useState } from 'react';
import ConfirmationModal from 'common/components/modal';
import toast from 'react-hot-toast';
import { triggerCustomUpload } from 'common/adapters/entities';

export const CustomUploadSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    function onClick() {
        setIsModalOpen(true);
    }

    const handleConfirmation = async (confirmed: boolean) => {
        setIsModalOpen(false);
        if (confirmed) {
            try {
                await triggerCustomUpload();
            } catch (error) {
                console.error("failed to trigger custom upload ", error)
                toast.error("Failed to trigger custom upload. Please try again.");
            }
        }
      };

    return (
        <>
            <div className={classes.container}>
                <h2>Custom Upload</h2>
                <p>
                    This button will trigger the custom data to be uploaded into the app.  
                    The files must be uploaded to Share Point and placed in their respective folder. 
                    Check Teams group "Software Development" channel "Aypa Analytics" for status after triggering it.
                    <br />
                    <a href="https://aypapower.sharepoint.com/:f:/r/sites/SoftwareDevelopment/Shared%20Documents/Aypa%20Analytics/Upload%20Data/Prod?csf=1&web=1&e=qQXCp3" target="_blank" rel="noopener noreferrer">SharePoint Upload</a>
                    <br />
                    <a href="https://aypapower.sharepoint.com/:w:/r/sites/SoftwareDevelopment/Shared%20Documents/Aypa%20Analytics/Upload%20Data/Upload%20Data%20Read%20Me.docx?d=wfd5721ead52c47e3b2bcbeade6bff1d0&csf=1&web=1&e=XefY2K" target="_blank" rel="noopener noreferrer">Read Me</a>
                    <br />
                </p>
                <DefaultButton title="Custom Upload" disabled={false} onClick={onClick} primary={true} ></DefaultButton>
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={handleConfirmation}
                    text={"Are you sure you want to upload the file(s)?"}
                ></ConfirmationModal>
            </div>
            
        </>
    )
}
