import { DivContainer50 } from "common/components/divContainer";
import { setBatteryHour } from "pages/home/stores/slices/settings-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select"
import { customStylesWithPadding } from "common/components/selectComponents"
import { INITAL_BATTERY_HOUR } from "pages/home/stores/slices/settings-slice";
import { ApplicationState } from "store";

interface Props {
    isLoadingEnabled: boolean,
    isEntitySelected: boolean
}
export const BatteryHours = ({ isLoadingEnabled, isEntitySelected }: Props) => {

    /**Selectors */
    const batteryHour = useSelector((store: ApplicationState) => store.settingsReducer.batteryHour);

    /**Contanst */
    const dispatch = useDispatch();
    const labelTitle = "Duration";
    const hoursList = [1,2,3,4,5,6,7,8,9,10,11,12]
    const options = hoursList.map((s: number) => {
        return { value: s, label: s.toString() + " hr" };
    });
    const initSelect = batteryHour ? batteryHour : INITAL_BATTERY_HOUR;
    const [selectedOption, setSelectedOption] = useState<Array<any>>([{value: initSelect, label: initSelect.toString() + " hr"}]);



    /**Callback */
    const handlerOnChangeHour = (data: any) => {
        dispatch(setBatteryHour(data.value));
        setSelectedOption(data)
    }


    return (
        <DivContainer50 label={labelTitle}>
            <Select
                id="batteryHours"
                onChange={handlerOnChangeHour}
                options={options}
                value={selectedOption}
                className='react-select-container'
                classNamePrefix="react-select"
                menuPlacement='auto'
                isDisabled={isLoadingEnabled || !isEntitySelected}
                styles={customStylesWithPadding}
            />
        </DivContainer50>
    )
}
