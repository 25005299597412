import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setGetPriceButton } from "pages/home/stores/slices/settings-slice";
// import { setPriceInit } from "pages/home/stores/actions/price-action";
import { priceInitialised } from "pages/home/stores/slices/prices-slice";

interface Props {
    disableGetPrice: boolean,
    isPriceListSelected: boolean
}

export const GetPriceButton = ({ disableGetPrice, isPriceListSelected }: Props) => {

    /**Conatants */
    const dispatch = useDispatch();
    const titleButton = "Get Price";

    /**Selector */
    const isPriceLoading = useSelector((store: ApplicationState) => store.pricesReducer.isLoadingChart);
    const getPriceButtonClicked = useSelector((store: ApplicationState) => store.settingsReducer.getPriceButtonclicked);


    const handlerOnClick = () => {
        // dont allow click if price button has already been clicked
        if (!getPriceButtonClicked) {
            // reset price reducer and settings reducer
            dispatch(priceInitialised());
            setTimeout(() => {
                dispatch(setGetPriceButton(true));
            }, 0);
        }
    }

    return (
        <button onClick={handlerOnClick} disabled={isPriceLoading || disableGetPrice || !isPriceListSelected}>{titleButton}</button>
    )
}
