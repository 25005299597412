import { combineReducers } from "redux";
import pricesReducer from "pages/home/stores/slices/prices-slice";
import settingsReducer from "pages/home/stores/slices/settings-slice"
import chartReducer from "pages/home/stores/slices/chart-slice"
import tablesReducer from "pages/home/stores/slices/table-slice"
import websocketReducer from "pages/home/stores/slices/websocket-slice"

export default combineReducers({
    tablesReducer,
    pricesReducer,
    settingsReducer,
    chartReducer,
    websocketReducer
    
});
