import classes from "./footer.module.scss";
import packageJson from '../../../../package.json'

const Footer = () => {
    const version = packageJson.version;
    const year = (new Date()).getFullYear();
    return (
        <div className={classes.footer}>
        <div  className={classes.footer__text}>&copy; {year} Aypa Power. All rights reserved. v{version} </div>
    </div>
    )
}

export default Footer;