import { all, takeLatest, takeEvery } from "redux-saga/effects";

/**Types */
import { SimulateChartTypes } from "pages/home/stores/types/simulate-chart-types";

/**Sagas */
import { fetchForecastKey, fetchMeanForecast, fetchRawForecast } from "pages/home/stores/sagas/mean-price-sagas";
import { fetchHybridRevenues } from "pages/home/stores/sagas/hybrid-revenues-sagas";
import { fetchFixedScheduleSummary, fetchRunFixedSchedule } from "pages/home/stores/sagas/fixed-schedule-sagas";
import { PriceTypes } from "pages/home/stores/types/price-types";
import { WebsocketTypes } from "pages/home/stores/types/websocket-types";
import {onMessage} from "pages/home/stores/sagas/websocket-sagas";

export default function* rootSagas() {

    yield all([
        takeLatest(SimulateChartTypes.FETCH_INIT_RUN_FIXED_SCHEDULE, fetchRunFixedSchedule),
        takeLatest(SimulateChartTypes.FETCH_FIXED_SCHEDULE_SUMMARY, fetchFixedScheduleSummary),
        takeLatest(SimulateChartTypes.FETCH_HYBRID_REVENUES, fetchHybridRevenues),
        takeEvery(PriceTypes.FETCH_INIT_RUN_FORECAST_LOOKUP, fetchMeanForecast),
        takeEvery(PriceTypes.FETCH_INIT_RUN_FORECAST_RAW_LOOKUP, fetchRawForecast),
        takeEvery(WebsocketTypes.WS_MESSAGE, onMessage),
        takeLatest(PriceTypes.FETCH_FORECAST_KEY, fetchForecastKey),
    ]);
}