import classes from "./flashScreen.module.scss";
import flash from "./../../assets/img/path.svg";

export interface FlashScreenProps {
    message: string
}
export const FlashScreen = ({ message }: FlashScreenProps) => {
    return (
        <div className={classes.flash}>
            <div className={classes.flash__img}>
                <img src={flash} alt="falsh" />
            </div>
            <div className={classes.flash__text}>
                { message }
            </div>
        </div>
    )
}
