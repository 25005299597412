import { ExceptionError } from "./exceptionError";

const ERROR_BAD_REQUEST = 'Bad request';
const ERROR_CROSS_DOMAIN = 'Cross Domain';
const ERROR = 'Error...';

export const handleException = (error: any) => {

    const errorMessage: ExceptionError = {
        codeStatus: error.response.status,
        message: getMessage(error),
        title: getTitleError(error),
    }

    return errorMessage;
}

const getTitleError = (error: any) => {
    switch (error.response.status) {
        case 400: return ERROR_BAD_REQUEST;
        case 401: return ERROR_CROSS_DOMAIN;
    }
    return ERROR;
}

const getMessage = (error: any) => {
    switch (error.response.status) {
        case 400: return error?.response?.data?.message?.join();
    }
}
