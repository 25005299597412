import classes from './style.module.scss';
import { CustomUploadSection } from './customUpload';
import { useAuth } from 'common/hooks/useAuth';
import { useAxiosInterceptors } from 'common/adapters/api';
import { DeleteForecastSection } from './deleteForecast';

interface AdminProps {
    getAypaToken: Function
}

export const AdminToolsPage = ({ getAypaToken }: AdminProps) => {
    useAxiosInterceptors(getAypaToken);
    const { isValid } = useAuth({ getAypaToken });


    return (
        <>
            { isValid &&
                <>
                <div className={classes.flexGroup}>
                    <CustomUploadSection />
                    <DeleteForecastSection />
                </div>
    
                </>
            }
        </>
    )
}
