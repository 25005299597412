import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Accordion, Card } from "react-bootstrap";

import { CardProps } from "common/interfaces/cardProps";
import { CustomToggle } from "../components/customToggle";
import { CardTitle } from "../components/accordionCard/cardTitle";
import { FlashScreen } from "common/components/flashScreen";
import classes from './../style.module.scss';
import { SimulationTableWrapper } from "./simulationTableWrapper";
import { ButtonDownloadMenu } from "../components/buttonsDownload/buttonDownloadMenu";

export const CardSimulationTable = ({ eventKey, stack, checkAypaToken }: CardProps) => {
    /**SELECTORS */
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);

    return (
        <Card className={classes.card}>
            <CustomToggle as={Card.Header} eventKey={eventKey} stack={stack}>
                <CardTitle title='TABLES' eventKey={settingsReducer.activeCard} order={eventKey} />
                {settingsReducer.activeCard === '2' && settingsReducer.simulateButtonclicked &&
                    <>
                        <ButtonDownloadMenu priceProfile={false} simulate={false} simulateTable={true} checkAypaToken={checkAypaToken}/>
                    </>
                }
            </CustomToggle>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className={classes.body}>
                    {!settingsReducer.simulateButtonclicked && !errorMessage && <FlashScreen message="Choose your settings and press Simulate" />}
                    {(settingsReducer.simulateButtonclicked || errorMessage) &&
                        <SimulationTableWrapper />

                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
