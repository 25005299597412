import { put } from "redux-saga/effects";
// import { setPriceFail } from "../actions/price-action";
import { requestCompletedRaw, requestFailed } from "../slices/prices-slice";
import { downloadCompleted } from "../slices/prices-slice";
import { successDownloadPriceLookup } from "../slices/prices-slice";
import { priceDataRawFailed, setGetPriceButton, setSimulateButton } from "../slices/settings-slice";
import {
    successRunPerfectForecast, setPerfectForesightRequestCompleted, successDownloadSimulateChart,
    setSimulateFail, setDownloadCompletedSimulateChart
} from "../slices/chart-slice";
import config from 'config';
import { successRunPriceLookup, requestCompleted } from "../slices/prices-slice";

export function* onMessage(action: any) {
    // console.log('action', action)

    const msg: string = action.payload.value;
    const host: string = action.payload.host;
    // console.log('host', host)
    // msg = {"action": str, "event_id": str, "data": str }
    // data could be string or JSON
    let body = null;
    let errMsg = null;
    try {
        body = JSON.parse(msg);
        // console.log('body', body)
        // errMsg will be JSON and have message or error.
        // good data should never be a json with these keys {message: xxx, error: yyy}
        errMsg = JSON.parse(body.data);
    } catch (e) {
        // console.log('not json')
    }
    if (!body || !body.action) {
        // body is empty or null
        if (host.includes(config.priceWs)) {
            yield put(requestFailed({ "message": "Message is malformed", "details": "Message is not JSON" }));
            yield put(setGetPriceButton(false));
        } else {
            yield put(setSimulateFail({ "message": "Message is malformed", "details": "Message is not JSON" }));
            yield put(setSimulateButton(false));
        }

        return;
    }
    switch (body.action) {
        case "mean-prices-stream":
            if (errMsg && (errMsg.message || errMsg.error)) {
                yield put(requestFailed(errMsg));
                yield put(setGetPriceButton(false));
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successRunPriceLookup({"data": body.data, "event_id": body.event_id}));
            } else {
                // process data and set 
                yield put(requestCompleted(body.event_id));
            }
            break;
        case "prices-stream":
            if (errMsg && (errMsg.message || errMsg.error)) {
                console.error('errMsg', errMsg)
                yield put(requestFailed(errMsg))
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successDownloadPriceLookup(atob(body.data)));
            } else {
                // process data and set 
                yield put(downloadCompleted());
            }
            break;
        case "forecast-stream":
            if (errMsg && (errMsg.message || errMsg.error)) {
                console.error('errMsg', errMsg)
                yield put(requestFailed(errMsg))
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successDownloadPriceLookup(atob(body.data)));
            } else {
                // process data and set 
                yield put(downloadCompleted());
            }
            break;
        case "extract-streams":
            if (errMsg && (errMsg.message || errMsg.error)) {
                console.error('errMsg', errMsg)
                yield put(requestFailed(errMsg));
                yield put(setGetPriceButton(false));
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successDownloadPriceLookup(atob(body.data)));
            } else {
                // process data and set 
                yield put(downloadCompleted());
            }
            break;
        case "extract-nodes":
            if (errMsg && (errMsg.message || errMsg.error)) {
                console.error('errMsg', errMsg)
                yield put(requestFailed(errMsg));
                yield put(setGetPriceButton(false));
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successDownloadPriceLookup(atob(body.data)));
            } else {
                // process data and set 
                yield put(downloadCompleted());
            }
            break;
        case "perfect-foresight":
            if (errMsg && (errMsg.message || errMsg.error)) {
                yield put(setSimulateFail(errMsg));
                yield put(setSimulateButton(false));
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                // console.log('body.data', body.data)
                yield put(successRunPerfectForecast(body.data));
            } else {
                // process data and set 
                yield put(setPerfectForesightRequestCompleted());
            }
            break;
        case "perfect-foresight-download":
            if (errMsg && (errMsg.message || errMsg.error)) {
                console.error('errMsg', errMsg)
                yield put(setSimulateFail(errMsg))
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successDownloadSimulateChart(atob(body.data)));
            } else {
                // process data and set 
                yield put(setDownloadCompletedSimulateChart());
            }
            break;
        case "raw-prices-stream":
            if (errMsg && (errMsg.message || errMsg.error)) {
                yield put(requestFailed(errMsg));
                yield put(priceDataRawFailed());
            } else if (body.data !== "COMPLETE") {
                // still getting data. append string data
                yield put(successRunPriceLookup({"data": body.data, "event_id": body.event_id}));
            } else {
                // process data and set 
                yield put(requestCompletedRaw(body.event_id));
            }
            break;
        default:
            if (host.includes(config.priceWs)) {
                yield put(requestFailed({ "message": "No valid endpoint", "details": "Action is not supported" }));
                yield put(setGetPriceButton(false));
            } else {
                yield put(setSimulateFail({ "message": "No valid endpoint", "details": "Action is not supported" }));
                yield put(setSimulateButton(false));
            }

    }

}
