import { ApplicationState } from "store";
import { useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import classes from './../style.module.scss';
import { CardTitle } from "../components/cardTitle";
import { CardProps } from "common/interfaces/cardProps";
import { FlashScreen } from "common/components/flashScreen";
import { CustomToggle } from "../components/customToggle";
import { SimulationWrapper } from "./simulationWrapper";
import { setErrorMessage } from "pages/home/stores/slices/chart-slice";
import { SimulationWrapperWebSocket } from "./simulationWrapperWebSocket";
import { HelpHover } from "common/components/helpButton";
import { ButtonDownloadMenu } from "../components/buttonsDownload/buttonDownloadMenu";

export const CardSimulationChart = ({ eventKey, stack, checkAypaToken }: CardProps) => {

    /**Constants */
    const dispatch = useDispatch();
    const initHelpPf = ["Displays the Perfect Foresight averages of Buy MW and Sell MW.",
        "Perfect Foresight takes price data and optimizes the best times to charge and discharge to maximize revenue.", 
        "It factors in the State Of Charge (SOC) of the BESS to only allow charging up to 100%",
        "It limits how many times it can discharge with Cycles per Day"]
    const initHelpFixed = ["Displays the Fixed Schedule hours to charge and discharge.",
        "Fixed Schedule finds the optimal consecutive hours to charge and to discharge to maximize revenue.", 
        "PV 8760 will apply Solar Data which will impact the optimal hours."]
    /**Selectors */
    const reducerChartSetting = useSelector((store: ApplicationState) => store.settingsReducer);
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);

    const title = reducerChartSetting.perfectForesight ? 'PERFECT FORESIGHT CHART' : reducerChartSetting.fixedSchedule ? 'FIXED SCHEDULE CHART' : 'CHART';

    useEffect(() => {
        dispatch(setErrorMessage(null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        reducerChartSetting.priceId,
        reducerChartSetting.sourceId,
        reducerChartSetting.locationId,
        reducerChartSetting.forecastId,
        reducerChartSetting.nodeId,
        reducerChartSetting.priceMaxtimestamp,
        reducerChartSetting.priceMintimestamp,
        reducerChartSetting.batteryPower,
        reducerChartSetting.roundTripEfficiency,
        reducerChartSetting.fixedSchedule,
        reducerChartSetting.perfectForesight,
        reducerChartSetting.system,
        reducerChartSetting.configuration
    ])

    return (
        <Card className={classes.card}>
            <CustomToggle as={Card.Header} eventKey={eventKey} stack={stack}>
                <CardTitle title={title} eventKey={reducerChartSetting.activeCard} order={eventKey} />
                {reducerChartSetting.activeCard === '1' && reducerChartSetting.simulateButtonclicked &&
                    <>
                        <ButtonDownloadMenu priceProfile={false} simulate={true} simulateTable={false} checkAypaToken={checkAypaToken}/>
                    </>
                }
            </CustomToggle>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className={classes.body}>
                    {!reducerChartSetting.simulateButtonclicked && !errorMessage && <FlashScreen message="Choose your settings and press Simulate" />}
                    {(reducerChartSetting.simulateButtonclicked || errorMessage) &&
                        <div className="scrollable">
                            {reducerChartSetting.fixedSchedule && <>
                                <div className={classes.sectionTitleWithHelp}>
                                    <div className={classes.sectionTitle}><b>Optimal Charge and Discharge Hours</b></div>
                                    <HelpHover messageList={initHelpFixed} largeMarg={false}></HelpHover>
                                </div> 
                                <SimulationWrapper />
                                </>}
                            {reducerChartSetting.perfectForesight && <> 
                                <div className={classes.sectionTitleWithHelp}>
                                    <div className={classes.sectionTitle}><b>Buy and Sell Averages</b></div>
                                    <HelpHover messageList={initHelpPf} largeMarg={false}></HelpHover>
                                </div> 
                                <SimulationWrapperWebSocket checkAypaToken={checkAypaToken}/>
                                </>}
                        </div>
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
