import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { Accordion, Card } from "react-bootstrap";

import classes from "./../style.module.scss";
import { CardTitle } from "../components/cardTitle";
import { CardProps } from "common/interfaces/cardProps";
import { FlashScreen } from "common/components/flashScreen";
import { CustomToggle } from "../components/customToggle";
import { PriceWrapper } from "./priceWrapper";
import { ButtonDownloadMenu } from "../components/buttonsDownload/buttonDownloadMenu";

export const CardPriceProfile = ({ eventKey, stack, checkAypaToken }: CardProps) => {

    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const errorMessage = useSelector((store: ApplicationState) => store.pricesReducer.errorMessage);
    const title1 = `PROFILE`

    return (
        <Card className={classes.card}>
            <CustomToggle as={Card.Header} eventKey={eventKey} stack={stack}>
                <CardTitle title={title1} eventKey={settingsReducer.activeCard} order={eventKey} />
                {settingsReducer.activeCard === '0' && settingsReducer.getPriceButtonclicked &&
                    <>
                        <ButtonDownloadMenu priceProfile={true} simulate={false} simulateTable={false} checkAypaToken={checkAypaToken}/>
                    </>
                }
            </CustomToggle>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className={classes.body}>
                    {!settingsReducer.getPriceButtonclicked && !errorMessage && <FlashScreen message="Choose your settings and press Get Prices" />}
                    {(settingsReducer.getPriceButtonclicked  || errorMessage) && <>
                        <div className="scrollable">
                            <PriceWrapper checkAypaToken={checkAypaToken}/>
                        </div>
                        </>
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
