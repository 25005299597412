import { DivContainer, DivContainer100 } from "common/components/divContainer";
import { PriceListData } from "common/interfaces/priceListData";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import classes from './style.module.scss';
import sprites from "common/assets/img/sprites.svg";
import { removePriceList } from "pages/home/stores/slices/settings-slice";
import { HelpHover } from "common/components/helpButton";

export const PriceList = ({simulateError}: any) => {
    const dispatch = useDispatch();
    const messageList = ["This is the list of prices that will be displayed", 
        "All sources can be added together.",  
        "Get Price max is 10. Simulate max is 1",
        "Can't mix Intervals (Hourly and 5min data)",
        "All actions use the price(s) in the Price List, not what is currently in the form."]
    
    /**Constants */
    const settings = useSelector((store: ApplicationState) => store.settingsReducer);

    const removeItem = (eventId: string) => {
        dispatch(removePriceList(eventId));
    };

    return (
        <>
            <DivContainer>
                <>
                <DivContainer100 label={"Price List"} help={<HelpHover messageList={messageList} largeMarg={true}></HelpHover>}>
                    <>
                    <div className={classes.priceList} >
                        {/* <h2 className={classes.priceListHeader}>Price List</h2> */}
                        {settings.priceList.map((item: PriceListData) => (
                            <div className={classes.priceListItem} key={item.eventId}>
                                <div>{item.name}</div>
                                <button  onClick={() => removeItem(item.eventId)}>
                                    <svg>
                                        <use xlinkHref={`${sprites}#x-mark`}></use>
                                    </svg>
                                </button>
                            
                            </div>
                        ))}
                    </div>
                    { simulateError && <span className="error">Only 1 for Simulations</span>}
                    </>
                </DivContainer100>
                </>
            </DivContainer>
        </>
    )
}

