import { customStylesWithPadding } from "common/components/selectComponents";
import { setPriceInterval } from "pages/home/stores/slices/settings-slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select"
import { ApplicationState } from "store";

interface Props {
    isLoadingEnabled: boolean
}

export const Interval = ({ isLoadingEnabled }: Props) => {

    let selectRef: any = null;

    const dispatch = useDispatch();

    const [listInterval, setListInterval] = useState<Array<any>>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [interval_Selected, setInterval_selected] = useState<object | null>(null);
    const isoSelected = useSelector((store: ApplicationState) => store.settingsReducer.isoId);

    const granularity = useSelector((store: ApplicationState) => store.settingsReducer.granularity);
    const priceId = useSelector((store: ApplicationState) => store.settingsReducer.priceId);
    const nodeIdSelected = useSelector((store: ApplicationState) => store.settingsReducer.nodeId);

    const handlerOnChangeInterval = (selectedOption: any, action: any) => {
        if (action.action !== 'clear') {
            setInterval_selected(selectedOption)
            dispatch(setPriceInterval(selectedOption.value))
        }

    }

    const clearValue = () => {
        selectRef.clearValue();
        setInterval_selected([])
    };

    useEffect(() => {
        const options: Array<any> = [
            { value: 'FIVEMIN_HOURLY', label: 'Hourly' },
            { value: 'FIVEMIN', label: '5 Min' }
        ]
        setListInterval(options)
    }, []);
    
    useEffect(() => {
        if (granularity) {
            setIsDisabled(granularity === 'HOUR')
            setInterval_selected({ value: granularity, label: "Hourly" })
        } else {
            clearValue();
            setIsDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceId, isoSelected, nodeIdSelected])


    return (
        <>
            <Select
                ref={ref => {
                    selectRef = ref;
                }}
                options={listInterval}
                value={interval_Selected ? interval_Selected : null}
                className='react-select-container-2ndCol'
                classNamePrefix="react-select"
                styles={customStylesWithPadding}
                menuPlacement='auto'
                isDisabled={isDisabled || isLoadingEnabled}
                onChange={handlerOnChangeInterval}
            />
        </>

    );
}