import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { SagaReturnType } from "@redux-saga/core/effects";

import { getMeanForecasts, getRawForecasts } from "common/adapters/meanPrices";
// import { setPriceFail } from "../actions/price-action";
import { requestFailed, successRunForecastRawLookup } from "../slices/prices-slice";
import { successRunForecastLookup } from "../slices/prices-slice";
import { priceDataRawFailed, setForecastKey, setGetPriceButton, setSimulateButton } from "../slices/settings-slice";
import { AllPriceData, AllRawPriceData } from "common/interfaces/priceData";
import { getForecastKey } from "common/adapters/entities";
import { runPerfectForesight, setSimulateFail } from "../slices/chart-slice";



const call: any = Effects.call;
const put: any = Effects.put;

/**
 * calls getMeanForecasts API and sets reducers based on return value.  Used in Get Price for source = Nodal, Zonal, Wood mac
 * @param action.parameter - data to pass to getMeanForecasts
 * @returns  forecast data used on Price Profile
 */
export function* fetchMeanForecast(action: any): SagaIterator {
    try {
        type MeanPrice = SagaReturnType<typeof getMeanForecasts>;
        const response: MeanPrice = yield call(getMeanForecasts, action.parameter);
        yield put(successRunForecastLookup({"data": response.data, "event_id": action.parameter.event_id}))
    } catch (error) {
        yield put(requestFailed(error as Error));
        yield put(setGetPriceButton(false))
    }
}

/**
 * calls getRawForecasts API and sets reducers based on return value.  Used in Get Price for source = Nodal, Zonal, Wood mac
 * @param action.parameter - data to pass to getRawForecasts
 * @returns  forecast data used on Price Profile
 */
export function* fetchRawForecast(action: any): SagaIterator {
    try {
        // successRunForecastRawLookup triggering PriceProfileTabs again
        type RawPrice = SagaReturnType<typeof getRawForecasts>;
        const response: RawPrice = yield call(getRawForecasts, action.parameter);
        yield put(successRunForecastRawLookup({"data": response.data, "event_id": action.parameter.event_id}))
    } catch (error) {
        yield put(requestFailed(error as Error));
        yield put(priceDataRawFailed())
    }
}

/**
 * calls getForecastKey API and sets reducers based on return value.  Used in Perfect Foresight Simulate
 * @param action.parameter - data to pass to getForecastKey
 * @returns  forecast key name
 */
export function* fetchForecastKey(action: any): SagaIterator {
    try {
        type ForecastData = SagaReturnType<typeof getForecastKey>;
        const response: ForecastData = yield call(getForecastKey, action.parameter);
        yield put(setForecastKey(response.data.key));
        yield put(runPerfectForesight());
    } catch (error) {
        yield put(setSimulateFail(error as Error));
        yield put(setSimulateButton(false))
    }
}


export const formatMeanPrices = (data: any) => {
    let new_data: AllPriceData;
    if (data) {
        if (data["hour"] != null) {
            new_data = {
                hour: data["hour"],
                hour_off: data["hour_off"],
                hour_month: data["hour_month"],
                hour_month_off: data["hour_month_off"],
                hour_year: data["hour_year"],
                hour_year_off: data["hour_year_off"],
                hour_wint_sum: data["hour_wint_sum"],
                hour_wint_sum_off: data["hour_wint_sum_off"],
                hour_year_month: data["hour_year_month"],
                hour_year_month_off: data["hour_year_month_off"],
                hour_year_wint_sum: data["hour_year_wint_sum"], 
                hour_year_wint_sum_off: data["hour_year_wint_sum_off"],
            }
        } else {
            new_data = {
                five_min: data["five_min"],
                five_min_off: data["five_min_off"],
                five_min_month: data["five_min_month"],
                five_min_month_off: data["five_min_month_off"],
                five_min_year: data["five_min_year"],
                five_min_year_off: data["five_min_year_off"],
                five_min_wint_sum: data["five_min_wint_sum"],
                five_min_wint_sum_off: data["five_min_wint_sum_off"],
                five_min_year_month: data["five_min_year_month"],
                five_min_year_month_off: data["five_min_year_month_off"],
                five_min_year_wint_sum: data["five_min_year_wint_sum"],
                five_min_year_wint_sum_off: data["five_min_year_wint_sum_off"]
            }
        }
        return new_data
    } else {
        return data;
    }
}

export const formatRawPrices = (data: any) => {
    let new_data: AllRawPriceData;
    if (data) {
        new_data = {
            data: data["data"]
        }
        return new_data
    } else {
        return data;
    }
}