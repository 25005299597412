import config from 'config';
import {api} from './api';


/**
 * Fetches forecast based on the params passed.
 * @param parameters.forecast_id - The ID of the forecast.
 * must supply either node_id or location_id
 * @param parameters.node_id - Optional node_id for the forecast when source = Nodal.
 * @param parameters.location_id - Optional location_id for the forecast when source = Wood Mac or Zonal.
 * @param parameters.start_timestamp - start time in MM/DD/YYYY.
 * @param parameters.end_timestamp - end time in MM/DD/YYYY.
 * @returns  forecast data used on Price Profile
 */
export const getMeanForecasts = (parameters: any) => {
    
    let params = new URLSearchParams();
    params.append("start_timestamp", parameters.start_timestamp);
    params.append("end_timestamp", parameters.end_timestamp);
    if ("location_id" in parameters) {
        params.append("location_id", parameters.location_id);
    } else {
        params.append("node_id", parameters.node_id);
    }
    if ("on_off_peak" in parameters) {
        params.append("on_off_peak", "true");
        params.append("on_peak_start", parameters.on_peak_start);
        params.append("on_peak_end", parameters.on_peak_end);
    }
    
    api.defaults.baseURL = config.baseUrl;
    return api.get(`forecast/${parameters.forecast_id}`, { params });
}

/**
 * Fetches forecast based on the params passed.  Gets raw data, not averaged
 * @param parameters.forecast_id - The ID of the forecast.
 * must supply either node_id or location_id
 * @param parameters.node_id - Optional node_id for the forecast when source = Nodal.
 * @param parameters.location_id - Optional location_id for the forecast when source = Wood Mac or Zonal.
 * @param parameters.start_timestamp - start time in MM/DD/YYYY.
 * @param parameters.end_timestamp - end time in MM/DD/YYYY.
 * @returns  forecast data used on Price Profile
 */
export const getRawForecasts = (parameters: any) => {
    
    let params = new URLSearchParams();
    params.append("start_timestamp", parameters.start_timestamp);
    params.append("end_timestamp", parameters.end_timestamp);
    if ("location_id" in parameters) {
        params.append("location_id", parameters.location_id);
    } else {
        params.append("node_id", parameters.node_id);
    }
    
    api.defaults.baseURL = config.baseUrl;
    return api.get(`forecast/${parameters.forecast_id}/raw`, { params });
}