import { useEffect, useState } from "react";
import { ApplicationState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setSimulateButton } from "pages/home/stores/slices/settings-slice";
import { Loading } from "../components/loading";
import { SimulationTabs } from "./simulationTabs";
import { useExecutePerfectForesight } from "../hooks/useExecutePerfectForesight";
import { useInitRunPerfectForesight } from "../hooks/useInitRunPerfectForesight";
import { fetchRunPerfectForesight, setSimulateFail } from "pages/home/stores/slices/chart-slice";
import { wsDisconnect } from "pages/home/stores/slices/websocket-slice";
import config from 'config';

interface Props {
    checkAypaToken: () => void;
}

export const SimulationWrapperWebSocket = ({checkAypaToken}: Props) => {
    /**Contants */
    const dispatch = useDispatch();
    const showCancelButton = true;
    const timeoutTime = 420000; //7min update error message to match

    /**Selectors */
    const isLoading = useSelector((store: ApplicationState) => store.chartReducer.isLoading);
    const errorMessage = useSelector((store: ApplicationState) => store.chartReducer.errorMessage);
    const simulateButtonclicked = useSelector((store: ApplicationState) => store.settingsReducer.simulateButtonclicked);
    const perfectForesight = useSelector((store: ApplicationState) => store.settingsReducer.perfectForesight);
    const executeRunPerfectForesight = useSelector((store: ApplicationState) => store.chartReducer.executeRunPerfectForesight);

    const [timeOutId, setTimeOutId] = useState<any>(null);

    /** Run Perfect Foresight Send API call*/
    useInitRunPerfectForesight(checkAypaToken);

    /** ExecuteOperation Triggered after onMessage receieve from WebSocket has completed*/
    useExecutePerfectForesight();

    /** Simulate button updates the state which then triggers useInitRunPerfectForesight */
    useEffect(() => {
        if (simulateButtonclicked && perfectForesight) {
           dispatch(fetchRunPerfectForesight());
            // TODO EE testing - use this section for testing and comment out fetchRunPerfectForesight
            // update chart-slice.ts too
            // dispatch(successRunPerfectForecast("{\"perfect_foresight_id\": \"3f90f209-b20a-4e9c-86c9-9e469bc1b69b\"}"));
            // setTimeout(() => {
            //     dispatch(setPerfectForesightRequestCompleted());
            // }, 0);
            // testing section
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulateButtonclicked, perfectForesight]);


    useEffect(() => {
        if (executeRunPerfectForesight) {
            if (timeOutId) {
                clearTimeout(timeOutId)
                setTimeOutId(null);
            }

            const timeOut = setTimeout(() => {
                dispatch(setSimulateFail({ "message": "Request timed out.", "details": "Request took longer than 7mins." }));
                dispatch(setSimulateButton(false));
            }, timeoutTime);
            setTimeOutId(timeOut)
        } else {
            setTimeOutId(null);
            clearTimeout(timeOutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRunPerfectForesight]);

    const cancelRequest = () => {
        setTimeout(() => {
            dispatch(wsDisconnect(config.fixedScheduleWs))
            dispatch(setSimulateFail(null));
            dispatch(setSimulateButton(false));
        }, 0);
    }

    const loadingMessage =
        <p>
            <b>Generating Results</b><br />
            This may take up to 7 minutes...
        </p>

    return (
        <Loading isLoading={isLoading} errorMessage={errorMessage} showButtonCancel={showCancelButton} loadingMessage={loadingMessage} callback={() => cancelRequest()}>
            <SimulationTabs isLoading={isLoading} />
        </Loading>
    );
}
