import { ApplicationState } from "store";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import { setPerfectForesightData, setSimulateButton } from "pages/home/stores/slices/settings-slice";
import { successOperationPerfectForesight, setSimulateFail } from "pages/home/stores/slices/chart-slice";
import { getMeanPerfectForesight, getSumPerfectForesight, getSummaryPerfectForesight } from "common/adapters/perfectForesight";

export const useExecutePerfectForesight = () => {
    // TODO Handle timeout
    /**Contants */
    const dispatch = useDispatch();

    /**Selectors */
    const chartReducer = useSelector((store: ApplicationState) => store.chartReducer);
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer)

    function processWebSocketData() {
        const loadedData = chartReducer.loadedData;
        // console.log('loaded data', loadedData)
        let priceJson = null;
        try {
            priceJson = JSON.parse(loadedData)
        } catch (e) {
            console.error(`Error parsing : ${e}`)
        }
        if (priceJson) {
            // error handling is in websocket-saga.ts
            responseWithoutError(priceJson);
        } else {
            responseDoneWithError({ "error": { "message": "Unable to parse data", "details": "JSON.parse() failed" } })
        }
    }

    const responseDoneWithError = (data: any) => {
        const message = data.error.message;
        const details = data.error.details;
        dispatch(setSimulateFail({ message, details }));
        dispatch(setSimulateButton(false));
    }

    async function responseWithoutError(data: any) {
        // perfect-foresight returns the id only - call other endpoints to get data
        const rre = settingsReducer.roundTripEfficiency ? settingsReducer.roundTripEfficiency : 88;
        const params = {
            'rated_power': settingsReducer.batteryPower, 'roundtrip_efficiency': rre / 100,
            'duration': settingsReducer.batteryHour
        }
        const perForId = data["perfect_foresight_id"]
        fetchAllForesightData(perForId, params).then(results => {
            // console.log('fetchAllForesightData results', results)
            dispatch(setPerfectForesightData({meanData:results[0].data, sumData:results[1].data, summaryData:results[2].data}));
            dispatch(successOperationPerfectForesight(perForId));
        }).catch((error: any) => {
            console.error('Error fetchAllForesightData:', error);
            dispatch(setSimulateFail({ message: error.message, details: error.details }));
            dispatch(setSimulateButton(false));
        });
    }

    async function fetchAllForesightData(perForId: string, params: object) {
        const results = await Promise.all([fetchForesightMeanData(perForId),
        fetchForesightSumData(perForId, params), fetchForesightSummaryData(perForId)]);
        return results;
    }

    async function fetchForesightMeanData(perForId: string): Promise<any> {
        const data = await getMeanPerfectForesight(perForId);
        return data;
    }

    async function fetchForesightSumData(perForId: string, params: object): Promise<any> {
        const data = await getSumPerfectForesight(perForId, params);
        return data;
    }

    async function fetchForesightSummaryData(perForId: string): Promise<any> {
        const data = await getSummaryPerfectForesight(perForId)
        return data;
    }

    useEffect(() => {
        if (chartReducer.completedPerfectForesight) {
            processWebSocketData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartReducer.completedPerfectForesight]);

    // return { errorMessage };
}
