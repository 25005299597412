import config from 'config';
import {api} from './api';

export const runFixedSchedule = (object: any) => {
    api.defaults.baseURL = config.fixedScheduleServiceBaseURL;
    return api.post(`fixed-schedules/%3arun`, object);
}

export const getFixedScheduleSummary = (fixedScheduleId: string) => {
    api.defaults.baseURL = config.fixedScheduleServiceBaseURL;
    return api.get(`fixed-schedules/${fixedScheduleId}/summary/`);
}