import { useEffect } from "react";
import { generateRandomNumberAsString } from "common/utils/utils";

interface Props {
    title: string,
    disabled: boolean,
    onClick: () => void,
    primary: boolean
}
function DefaultButton ({ title, disabled, onClick, primary}: Props) {
    const idNum = generateRandomNumberAsString(5) + "button"
    const className = primary ? "" : "secondary"

    useEffect(() => {
        const handleClick = (event: any) => {
          onClick()
        };
    
        const button = document.getElementById(idNum);
        if (button) {
          button.addEventListener('click', handleClick);
    
          return () => {
            button.removeEventListener('click', handleClick);
          };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
      return <button className={className} id={idNum} disabled={disabled}>{title}</button>;
}

export default DefaultButton;
