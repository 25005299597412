import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";

import { SagaReturnType } from "@redux-saga/core/effects";
import { getFixedScheduleSummary, runFixedSchedule } from "common/adapters/fixedSchedule";
import { successRunFixedSchedule, failedRunFixedSchedule, executeFixedScheduleSummaryComplete } from "../slices/chart-slice";
import { addDayToDateStr, formatDate } from "common/utils/utils";
import { setSimulateButton } from "../slices/settings-slice";


const call: any = Effects.call;
const put: any = Effects.put;

export function* fetchRunFixedSchedule(action: any): SagaIterator {

    try {
        type RunFixedSchedule = SagaReturnType<typeof runFixedSchedule>;
        const parameters = getParametersRunFixedSchedule(action.settingsReducer);
        const response: RunFixedSchedule = yield call(runFixedSchedule, parameters);
        yield put(successRunFixedSchedule(response.data));

    } catch (error) {
        yield put(failedRunFixedSchedule(error));
        yield put(setSimulateButton(false));
    }
}

const getParametersRunFixedSchedule = (settingsReducer: any) => {
    const price = settingsReducer.priceList[0];
    const endDate = price.dateRange ? price.dateRange[1] : price.priceMaxtimestamp;
    const endDateStr = addDayToDateStr(endDate);
    // node name is nodeBus or nodeName
    const node_name = price.nodeBus ? price.nodeBus : price.nodeName;
    return {
        "rated_power": settingsReducer.batteryPower,
        "rated_capacity": settingsReducer.batteryPower * settingsReducer.batteryHour,
        "charge_efficiency": settingsReducer.roundTripEfficiency / 100,
        "discharge_efficiency": settingsReducer.roundTripEfficiency / 100,
        "stream_id": price.streamId,
        "forecast_location": settingsReducer.forecastKey,
        "start_date": formatDate(price.dateRange ? price.dateRange[0] : price.priceMintimestamp),
        "end_date": formatDate(endDateStr),
        "pv_8760_id": settingsReducer.configuration,
        "granularity": price.granularity ? price.granularity : "HOUR",
        "timezone": settingsReducer.priceTimezone,
        "node_name": node_name
    };
}

export function* fetchFixedScheduleSummary(action: any): SagaIterator {
    try {
        type FixedScheduleSummary = SagaReturnType<typeof getFixedScheduleSummary>;
        const response: FixedScheduleSummary = yield call(getFixedScheduleSummary, action.fixedScheduleId);
        //only show the first 20 entries
        response.data.points.sort((a: any, b: any) => (a.total_revenue > b.total_revenue) ? -1 : ((b.total_revenue > a.total_revenue) ? 1 : 0));
        yield put(executeFixedScheduleSummaryComplete({data: response.data.points.slice(0, 19), error: null}));
    } catch (error) {
        console.error('fetchFixedScheduleSummary', error)
        yield put(executeFixedScheduleSummaryComplete({data: null, error: error}));
    }

    return 
}
